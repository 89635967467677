// File: change_email.tsx
//

import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {ActionCodeSettings,onAuthStateChanged,
    verifyBeforeUpdateEmail, getAuth } from 'firebase/auth';
import { MainLayout} from '../utils/main_layout';
import {useNavigate} from 'react-router-dom';
import { LOGGER } from '../../utils_common/logger';
import * as firebase_auth from 'firebase/auth';
import {get_portal_domain_url } from '../../config/firebase_config';
import { MsgDialog,MsgDialogProps} from '../../utils_common/msg_dialog';
import { OLDPUB_HOME_URL, OLDPUB_SIGNIN_ENDING_URL } from '../../extlinks/portal_comm_types';
//import { useAdminPagesContext } from '../../pages_admin/utils_admin/admin_pages_provider';
//import { AdminRole } from '../../extlinks/portal_comm_types';

const TAG='change_email_verified.tsx: ';
const DEBUG=true;

function get_action_code_settings(_new_email:string) :  ActionCodeSettings |null {

    /*
    if(get_on_local_host()) {
	toast("note: running on local host: verification link will open in web domain");	
    }
    */
    
    const portal_domain_url = get_portal_domain_url();

    if(portal_domain_url==null) {
	console.error(TAG+'get_action_code_settings: portal_domain==null');
	return null;
    }

    // putting an unauthorized page wont  work because it will get
    // directed to '/'
    const url = portal_domain_url + OLDPUB_SIGNIN_ENDING_URL;

    const action_code_settings : ActionCodeSettings = {
	url: url,
    };

    return action_code_settings;
}

export function ChangeEmailVerified() : React.ReactElement {

    const count = React.useRef<number>(0);
    {
	const navigate = useNavigate();	
	count.current = count.current+1;
	if(DEBUG) console.log('***count='+count.current);
	if(count.current > 1000) navigate(OLDPUB_HOME_URL);
    }

    if(count.current===1) {
	LOGGER.log_event('ENTER_CHANGE_EMAIL_VERIFIED','change_email_verified.tsx');
	console.info(TAG+'ENTER_CHANGE_EMAIL_VERIFIED');
    }
    
    const [ dialog, setDialog ] =
	React.useState<MsgDialogProps>({ msg:'', button1_label:'' , close:()=>{}});

    const close = ():void=> {
	setDialog({msg:'', button1_label:'', close:()=>{} });
	console.log('in close()');
    }
    
    const [ currentEmail , setCurrentEmail ] = React.useState<string>('');
    
    const [ msg , setMsg ] = React.useState<string>('');

    const [newEmail,  setNewEmail] = React.useState<string>('');

    const parse_error = (error : any) : void => {
	if( ! error.code) {
	    setMsg('Error changing email.');	    
	} else if (error.code === 'auth/invalid-email') {
            setMsg('Input is not a valid email address.');
	} else if (error.code === 'auth/email-already-in-use') {
            setMsg('Email is already in use.');	    
	} else {
	    setMsg('Error changing email. Try logging out and signing in again.');
	}
    }

    const navigate = useNavigate();

    /*
    
    const mainStateContext = useAdminPagesContext();
    
    React.useEffect( ()=> {

	if(mainStateContext.current_user_admin_role === undefined ||
	    mainStateContext.current_user_admin_role !== AdminRole.NONE) {
	    navigate(ADMIN_CANNOT_CHANGE_EMAIL_URL);
	}
    },[]);
    */    

    const auth = getAuth();
    
    onAuthStateChanged(auth, user => {
        if(user && user.email) {
            setCurrentEmail(user.email);
	} else {
	    setCurrentEmail('');
	}
    })


    
    const logout = ():void => {
        firebase_auth.signOut(auth)
        .then(() => navigate(OLDPUB_SIGNIN_ENDING_URL))
        .catch( (error) => {
	    LOGGER.error(TAG+'PT0: error='+String(error));
	    console.error(TAG+'PT0: error='+String(error));
	}
	);
    }
    
    const onSubmit = ():void => {
	
	console.log({newEmail});
	
	if(newEmail.length===0 || newEmail.indexOf('@') <= 0 ||
	    newEmail.indexOf('@') === newEmail.length-1) {
	    setMsg('Invalid email address.');
	    return
	} else {

	    const action_code_settings = get_action_code_settings(newEmail);
	    
	    if(auth !== null && auth.currentUser &&  action_code_settings) {
		verifyBeforeUpdateEmail(auth.currentUser, newEmail,
		    action_code_settings)
		    .then( ()=> {
			LOGGER.log_event('VERIFY_EMAIL_SUCCESSFUL','');
			if(DEBUG) console.info(TAG+'VERIFY_EMAIL_SUCCESSFUL');

			setDialog({msg: "A verification link has been sent your new email. Check the spam folder if you don't see it.",
			    button1_label:'OK',
			    button1_handle: logout,
			    close
			});

			//setTimeout( logout, 1000*4);

		    }).catch( (error)=> {
			LOGGER.error(TAG+'PT2:error=' + String(error));
			console.error(TAG+'PT2:error=' + String(error));
			parse_error(error);
		    });

		
	    } else {
		LOGGER.error('Not authorized to change email.');
		console.error(TAG+'Not authorized to change email.');
		setMsg('Not authorized to change email.');
	    }
	}
    }
    
    const onChange =
	(event: React.ChangeEvent<HTMLInputElement>) :void => {
	    setMsg('');	    
	    setNewEmail(event.target.value.trim());
	};

    return (
	
	<MainLayout title='Change Email'>

	  <Box sx={{ marginTop:'2em',display: 'flex',
	      flexDirection: 'column',
	      justifyContent: 'space-between',
	      alignItems:'center' }}>

	      <Typography display='inline' sx={{fontWeight:'bold'}}>
	      {'Current Email: '}
	      </Typography>
	      <Typography  display='inline'>	      	      
              {currentEmail}
	      </Typography>

	    <Box style={{height:'1em'}}/>	    

              <TextField
		  label="New Email"
		  id="outlined-start-adornment"
		  sx={{ m: 1, width:'30ch'}}
		  onChange={onChange}

               />
	      
	      <Box style={{height:'2em'}}/>
	    
	      <Button variant={"outlined"} onClick={()=>onSubmit()}>
	    Submit
	      </Button>
	      
	      <Box style={{height:'2em'}}/>	    
	      
	      <Typography  sx={{maxWidth:'60ch',
		  minHeight:'2em', fontWeight : "bold" }}
			   variant="body1" color="textSecondary">
	      {msg}
	      </Typography>
	      
	  </Box>
	  
	<MsgDialog {...dialog} />
	
	</MainLayout>      
    );
}

