// File: ecomm_auth_provider.tsx
//

import React from 'react';
import { getAuth,  User } from 'firebase/auth';

const TAG='ecomm_auth_provider.tsx: ';
const DEBUG=true

export interface ECommAuthContextState {
    user: User|null;
    refresh: ()=>void;    
}

export const ECommAuthContext : React.Context<ECommAuthContextState>
         = React.createContext<ECommAuthContextState>({ user : null, refresh:()=>{} });

export const useECommAuthStatus = () : ECommAuthContextState => {
    return React.useContext<ECommAuthContextState>(ECommAuthContext);
}

export function ECommAuthProvider(props : { children : React.ReactElement | React.ReactElement[]}) : React.ReactElement {
	
	const [ currentUser , setCurrentUser] = React.useState<User|null>(null);

	const refresh = ():void => {

	    if(DEBUG) console.log(TAG+'entering refresh');
	    
	    const auth = getAuth();
	    const user = auth.currentUser;
	    if(user) setCurrentUser(user);
	}
	
	React.useEffect( ()=> {

	    const auth = getAuth();

	    if(!auth) {
		console.error(TAG+'!auth');
	    }

	    auth.onIdTokenChanged((user) => {
		if(user) setCurrentUser(user);
	    });
	    
	    const unsubscribe =
		auth.onAuthStateChanged((usr:User|null)=> {
		    try {
			
			if(DEBUG) console.log(TAG+'onAuthStateChanged');
			setCurrentUser(usr);

		    } catch(error) {
			console.error(TAG+'onAuthStateChanged: error='+error);
		    }
		});

	    return unsubscribe;
	    
	},[]);

    return (
        <ECommAuthContext.Provider value={{ user: currentUser, refresh:refresh }}>
            {props.children}
        </ECommAuthContext.Provider>
    )
}

