// File: landing_page.tsx
//


import React from 'react';
import { useNavigate} from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
//import { MsgAlertDialog } from '../../utils_common/alert_dialog';
import { ChallengeQuestions } from '../../utils_common/challenge_questions';
import {SessionTimeout} from '../../utils_common/session_timeout';
import { ECOMM_TOKEN_TIMED_OUT_URL } from '../../extlinks/portal_comm_types';

//const DEBUG=true;
//const TAG="landing_page.ts: ";

const theme = createTheme();

export function EmailCommLandingPage(props: {
    challengeOff?:boolean,
    children: React.ReactElement | React.ReactElement[]
}) : React.ReactElement
{
    
    const [openQuestions, setOpenQuestions ] = React.useState<boolean>(true);
    
    const [answerCorrect, setAnswerCorrect] = React.useState<boolean>(false);
    
    React.useEffect(()=>{
	if(answerCorrect) {
	    setOpenQuestions(false);
	}
    },[answerCorrect])

    //const [maxTriesReached, setMaxTriesReached] = React.useState<boolean>(false);

	const navigate = useNavigate();
	
	const onTimeout = ():void => {
	    navigate(ECOMM_TOKEN_TIMED_OUT_URL);
	}

	const onTimeoutMsg = (secs : number) : string=> {
	    return `Time out in  ${secs} secs due to inactivity.`;
	}
	
    return (
		
 <ThemeProvider theme={theme}>
    <Container component="main" maxWidth='md' >

      <CssBaseline/>
			
    {  ! answerCorrect && ! openQuestions &&
	(<Box sx={{mt:10}} >
	  <Typography sx={{mb:2}} textAlign="center" variant='h5'>Sorry, the answer is incorrect, or</Typography>
	  <Typography sx={{mb:2}} textAlign="center" variant='h5'>our website is having problems.</Typography>
	  <Typography textAlign="center" variant='h5'>You may try again.</Typography>
	</Box>	)
    }
	
   { (answerCorrect || (props.challengeOff === undefined || props.challengeOff))
       &&  (<> {props.children} </>)
   }
       
   { (props.challengeOff === undefined ||  ! props.challengeOff ) &&

	(<>		
			
	<SessionTimeout onTimeout={onTimeout}
   		        onTimeoutMsg={onTimeoutMsg}/>
		
	<ChallengeQuestions
		setMaxTriesReached={()=>{}}
		open={openQuestions}
		setOpen={setOpenQuestions}
  	        setAnswerCorrect={setAnswerCorrect}/>
       </>)
   }
	
   </Container>
</ThemeProvider>);
 

}



