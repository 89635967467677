// File: main_layout.tsx
//

import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Copyright} from '../../utils_common/copyright';
import { TDBAppLogoLarge } from '../../utils_common/tdb_large_logo';
import Typography from '@mui/material/Typography';
import { UserProfileMenu } from './user_profile_menu';
import { useAdminPagesContext } from './admin_pages_provider';
import { Spinner } from '../../utils_common/spinner';
import {AdminSideBar} from './sidebar';
import CloseIcon from '@mui/icons-material/Close';
import { ADMIN_SIGNIN_ENDING_URL } from '../../extlinks/portal_comm_types';

//const TAG='main_layout.tsx: ';

const drawerWidth = 200;

export interface MainLayoutProps {
    title : string,
    children : React.ReactElement[] | React.ReactElement
}

const theme = createTheme();

export function MainLayout(props : MainLayoutProps) : React.ReactElement {
    
    const main_pages_state = useAdminPagesContext();

    const waiting = main_pages_state.is_loading;
    
    /*    
    const [waiting, setWaiting] = React.useState<boolean>(true);
    React.useEffect( ()=> {
	setWaiting(main_pages_state.purchase_info == null);
    }, [main_pages_state.purchase_info]);

    */

    React.useEffect( ()=>{
	main_pages_state.refresh();
    },[]);
    
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () :void => {
	setMobileOpen(!mobileOpen);
    }
    
    return (
    <ThemeProvider theme={theme}>	
    <Box sx={{ display: 'flex', width:'100vw' }}>
      <CssBaseline />
      
      <AppBar
	  component='nav'	  
	  style={{
	      width: '100%',
	      zIndex:10000,
	      backgroundColor : "#8e0d10",
	      display:'flex', flexDirection:'row', 
	      justifyContent: 'flex-start', alignItems : 'center'}}
          sx={{
	      pr:2,
          width: '100vw'
        }}
        position="fixed"	  
      >
	
        <Box  style={{paddingLeft:'1em'}}   position='relative'>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={()=>handleDrawerToggle()}
            sx={{ mr: 2, display: { sm: 'none' }  }}
          >
             <MenuIcon />
          </IconButton>
        </Box>

        <Toolbar  style={{fontSize: "1.2em", marginLeft:'auto', marginRight:'auto'}}>
	  {props.title}
        </Toolbar>

	<Box style={{position:"relative", right:'10px'}} >
	  <UserProfileMenu signInUrl={ADMIN_SIGNIN_ENDING_URL}/>

	</Box>	  

    </AppBar>
      
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
      >

	
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={()=>handleDrawerToggle()}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },

          }}>
	  
         <Toolbar/>
	  
          <Box

	    component='div'
	    style={{height:'1px'}}
            sx={{
		mt:3,mb:1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              px: [1],
            }}
          >
	  
            <IconButton onClick={ handleDrawerToggle}>
               <CloseIcon/>	      
            </IconButton>	  
          </Box>

	  
	  <AdminSideBar signInUrl={ADMIN_SIGNIN_ENDING_URL}
			current_user_admin_role={main_pages_state.current_user_admin_role} />
	  
        </Drawer>
	
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          open
        >

	  <Toolbar/>
	  
	  <AdminSideBar
	      signInUrl={ADMIN_SIGNIN_ENDING_URL}	      
	      current_user_admin_role={main_pages_state.current_user_admin_role} />	  
        </Drawer>
      </Box>
      
      <Box
	style={{padding:'1em'}}
        component="main"
        sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px) ` } }}
      >

          <Toolbar>
          </Toolbar>
	  
	<Typography align='center' sx={{mt:2, mb:2}}>	  
	  <TDBAppLogoLarge/>
	</Typography>	     
	     
	   <Box style={{height:'0.75em'}}/>
	    {props.children}
          <Copyright  sx={{ mt: 4, mb: 4 }}/>
	  
      </Box>

      <Spinner open={waiting} />
      
    </Box>
    </ThemeProvider>
  );

}
