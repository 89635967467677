// File: routes_new_auth_password.tsx
//

import {Route} from "react-router-dom";
import {Routes} from 'react-router-dom';

import {SignIn} from "../pages_pub/reg_pages/signin";
import { Home } from "../pages_pub/portal_pages/home";
import {EmailVerified} from "../pages_pub/reg_pages/email_verified";
import {EmailNotVerified} from "../pages_pub/reg_pages/email_not_verified";
import {ForgotPassword} from "../pages_pub/reg_pages/forgot_password";
import {ActivateMobileApp} from "../pages_pub/portal_pages/activate_mobile_app";
import {MobileUpgrade} from "../pages_pub/portal_pages/mobile_upgrade/mobile_upgrade";
import { MobileUpgradeLaunchPopup } from "../pages_pub/portal_pages/mobile_upgrade/mobile_upgrade_launch_popup";
import { MobileActInstruct } from "../pages_pub/portal_pages/mobile_upgrade/mobile_act_instruct";
import {NotFound} from "../pages_pub/other_pages/not_found";
import {DisplayMobileDevices} from "../pages_pub/portal_pages/display_mobile_devices";
import {DesktopAppLicenseKey} from "../pages_pub/portal_pages/desktop_app_license_key";
import { ChangePassword } from "../pages_pub/portal_pages/change_password";
import { PubRequireAuth } from "../pages_pub/PubRequireAuth";
import { PubMainPagesProvider } from '../pages_pub/utils/pub_main_pages_provider';
import { MobileUpgradeDirectToLink } from "../pages_pub/portal_pages/mobile_upgrade/mobile_upgrade_direct_to_link";
import { MOBILE_UPGRADE_USES_POPUP, USE_ROUTES_ECOMM_AUTH, USE_ROUTES_ECOMM_TOKEN, USE_ROUTES_OLDPUB } from '../config/config';
import { TimedOut } from "../pages_other/timed_out";
import { MobileLiteVersionLinks } from "../pages_pub/portal_pages/mobile_upgrade/mobile_lite_version_links";
import { LicensesStatusForMobUpgrade } from "../pages_pub/portal_pages/mobile_upgrade/licenses_status_for_mobupgrade";
import { PreHome } from "../pages_pub/portal_pages/prehome";
import { CreateAcctFromEmailLink } from "../pages_pub/reg_pages/create_acct_from_email_link";
import { OLDPUB_CREATE_ACCT_ENDING_URL, OLDPUB_MOBILE_UPGRADE_CREATE_ACCT_ENDING_URL,
    OLDPUB_SIGNIN_ENDING_URL, OLDPUB_MOBILE_UPGRADE_SIGNIN_ENDING_URL,
    OLDPUB_PASSWORD_RESET_ENDING_URL,
    OLDPUB_PWLESS_SIGNIN_ENDING_URL,
    OLDPUB_PWLESS_REQ_LINK_ENDING_URL,
    OLDPUB_CREATE_ASSIGNED_ACCT_ENDING_URL,
    OLDPUB_EMAIL_NOT_VERIFIED_ENDING_URL,
    OLDPUB_EMAIL_VERIFIED_ENDING_URL,
} from '../extlinks/portal_comm_types';
import { PasswordResetFromLink } from "../pages_pub/reg_pages/password_reset_from_link";
import { CreateAcctAssignedPassword } from "../pages_pub/reg_pages/create_acct_for_assigned_password";
import { PWLessRequestEmailLink } from "../pages_pub/reg_pages/pwless_request_email_link";
import { PWLessSignInFromEmailLink } from "../pages_pub/reg_pages/pwless_signin_from_email_link";

import { RoutesAdmin } from "./routes_admin";
import { ECommAuthRoutes } from './routes_ecomm_auth';
import { ECommTokenRoutes } from './routes_ecomm_token';
import { RoutesOldPub } from "./routes_oldpub";
import { OLDPUB_NOT_FOUND_URL,
         OLDPUB_TIMED_OUT_URL,
    OLDPUB_FORGOT_PASSWORD_ENDING_URL,} from "../extlinks/portal_comm_types";

interface PageProps {
}

export function AppRoutes(_props:PageProps) : React.ReactElement {

    
    return (
	<Routes>
	
	  <Route path={OLDPUB_NOT_FOUND_URL} element={<NotFound />} />

	  <Route path={OLDPUB_TIMED_OUT_URL} element={<TimedOut />} />	  

	{/*
	  <Route  path='/'
		  element={<SignIn name={"Sign In"}/>}
          />
	*/}

         {/* we have two separate route names for signin so we can avoid infinite loops*/}
	  <Route  path={OLDPUB_SIGNIN_ENDING_URL} element={<SignIn name={"Sign In"}/>}
          />

	  <Route  path={OLDPUB_MOBILE_UPGRADE_SIGNIN_ENDING_URL} element={<SignIn name={"Mobile Upgrade Sign In"}/>}
          />	  

	  <Route path={OLDPUB_CREATE_ACCT_ENDING_URL+'/:url_params'} element={<CreateAcctFromEmailLink name={"Create Account"}/>} />

	  <Route path={OLDPUB_MOBILE_UPGRADE_CREATE_ACCT_ENDING_URL+'/:url_params'}
		 element={ <CreateAcctFromEmailLink name={"Mobile Upgrade Create Acct"}/>} />

	  <Route path={OLDPUB_PASSWORD_RESET_ENDING_URL+'/:url_params'}
	        element={<PasswordResetFromLink name={"Password Reset"}/>} />

	  <Route path={OLDPUB_CREATE_ASSIGNED_ACCT_ENDING_URL}
		 element={ <CreateAcctAssignedPassword name={"Create Acct"}/>} />

	
	  <Route path={OLDPUB_PWLESS_REQ_LINK_ENDING_URL}
		 element={<PWLessRequestEmailLink name={"Request Email Signin Link"} />} />

	  <Route path={OLDPUB_PWLESS_SIGNIN_ENDING_URL+ '/:token'}
                 element={<PWLessSignInFromEmailLink name={"Signin"} />} />

	  <Route path={OLDPUB_FORGOT_PASSWORD_ENDING_URL}
		 element={<ForgotPassword name={"Forgot Password"}/>}
          />
	  
	  <Route path={OLDPUB_EMAIL_VERIFIED_ENDING_URL+'/:tag/:token'}
	      element={<EmailVerified name={"Email Verified"}/>}
             />

	  <Route
              path={OLDPUB_EMAIL_NOT_VERIFIED_ENDING_URL}
              element={<EmailNotVerified name={"Email Not Verified"}/>}
           />

	  { USE_ROUTES_OLDPUB && RoutesOldPub() }

	  { USE_ROUTES_ECOMM_AUTH && ECommAuthRoutes() }
	  
	  { USE_ROUTES_ECOMM_TOKEN && ECommTokenRoutes() }	  
	  
	  {RoutesAdmin()}

	</Routes>);
}    

