// File: config.ts
//

// if false, directs to FS mobile upgrade product webpage
export const MOBILE_UPGRADE_USES_POPUP=true;

export const ECOMM_TOKEN_TESTING=true;

export const ECOMM_AUTH_TESTING=true;

export const USE_ROUTES_OLDPUB=false;

export const USE_ROUTES_ECOMM_AUTH=true;

export const USE_ROUTES_ECOMM_TOKEN=false;
