// File: pub_main_layout_provider.tsx
//

import React from 'react';
import * as firebase_auth from 'firebase/auth';
import { getAuth} from 'firebase/auth';
import { useNavigate } from 'react-router';
import { useAuthStatus } from '../../auth_provider/use_auth';
import { AdminRole, ADMIN_SIGNIN_ENDING_URL } from '../../extlinks/portal_comm_types';
//import { getAdminServerComm } from './admin_server_comm';
//import { get_session_token } from '../../utils_common/logger';
import { SessionTimeout } from '../../utils_common/session_timeout';
import { clear_reg_logger_session_token } from '../../utils_common/reg_logger_session_token';
import {  LOGGER } from '../../utils_common/logger';

const TAG='admin_pages_provider.tsx: ';
const DEBUG =false;

export interface AdminPagesContextState {
    current_user_admin_role: AdminRole;
    is_loading : boolean;
    refresh : ()=>void;
}

export const AdminPagesContext : React.Context<AdminPagesContextState>
         = React.createContext<AdminPagesContextState>({ current_user_admin_role: AdminRole.NONE,
	     is_loading : false,
	     refresh : ()=>{}
	 });

export const useAdminPagesContext = () : AdminPagesContextState => {
    return React.useContext<AdminPagesContextState>(AdminPagesContext);
}

export const AdminPagesProvider = 
    (props : { children : React.ReactElement | React.ReactElement[]}) : React.ReactElement => {

	const [ userAdminRole, setUserAdminRole] =
	    React.useState<AdminRole>(AdminRole.NONE);

	const [ isLoading, setIsLoading ] = React.useState<boolean>(true);

	const { user} = useAuthStatus();

	const fetch_impl = async ():Promise<void> => {

	    setIsLoading(true);
	    
	    if(DEBUG) console.log(TAG+'entering fetch');
	    if( user &&  user.emailVerified) {

		if(DEBUG) console.log(TAG+'fetch: PT1');

		const idTokenResult = await user.getIdTokenResult();
		if(DEBUG) console.log(TAG+' idTokenResult.claims=', idTokenResult.claims);		
		let user_admin_role = AdminRole.NONE;
		if('admin_role' in idTokenResult.claims) {
		    const admin_role_str =  idTokenResult.claims['admin_role'];
		    const x = AdminRole[ admin_role_str as keyof typeof AdminRole ];
		    if(x!== undefined) user_admin_role = x;
		}

		if(DEBUG) console.log(TAG+'user_admin_role=' + user_admin_role);

		setUserAdminRole(user_admin_role);
		
	    }
	    setIsLoading(false);	    
	}

	const fetch = ():void => {
	    fetch_impl().then(()=>{}).catch(()=>{});
	}
	
	React.useEffect( ()=> {
	    fetch();
	},[user]);

	const navigate = useNavigate();
	
	const onTimeout = ():void => {
	    
	    const auth = getAuth();
	    
	    firebase_auth.signOut(auth).
		then(() => {
		    LOGGER.info(DEBUG, TAG+'timeout idle: logging out');
		    clear_reg_logger_session_token();
		    navigate(ADMIN_SIGNIN_ENDING_URL);
		}).
		catch( (error) => {
		    LOGGER.error(TAG+'timeout idle: logging out error=' + String(error));
		    console.error(error);
		    navigate(ADMIN_SIGNIN_ENDING_URL);		    
		});
	    navigate(ADMIN_SIGNIN_ENDING_URL);		    	    
	    
	}

	const onTimeoutMsg = (secs : number):string=> {
	    return `Logging out in ${secs} secs due to inactivity.`;
	}	
	
    return (
        <AdminPagesContext.Provider value={{
	    refresh: fetch,
	    current_user_admin_role : userAdminRole,
	    is_loading:isLoading
	}}>
          <SessionTimeout onTimeout={onTimeout}
			  onTimeoutMsg={onTimeoutMsg}
			  timeout={1000*240*60}/>
            {props.children}
        </AdminPagesContext.Provider>
    )
}
