// File: EcommAuthRequireAuth.tsx
//

import React from 'react';
import { useParams, Outlet, Navigate } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useECommAuthStatus } from './ecomm_auth_provider';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import { ECOMM_AUTH_EMAIL_NOT_VERIFIED_URL,
    ECOMM_AUTH_LOGIN_URL } from '../extlinks/portal_comm_types';

const TAG='ECommAuthRequireAuth.tsx: ';

const DEBUG=true;

const theme = createTheme();

export const ECommAuthRequireAuth =
    () : React.ReactElement => {

    const params = useParams();	
    const {user} = useECommAuthStatus();

    const urlParams = React.useRef<string>('');
	
    React.useEffect( ()=> {
	if(params.url_params != null) {
	    urlParams.current = '/' + params.url_params;
	}
    },[params.url_params]);
	
    React.useEffect( ()=> {
	if(user) {
	    if(DEBUG) {
		console.log(TAG+'user != null')
		console.log(TAG+'user.emailVerified='+user.emailVerified);
	    }
	} else {
	    if(DEBUG) {	    
		console.log(TAG+'user == null')
	    }
	}
    }, [user]);
    
    return (
	<>
	{ user == null &&
  	    ( <ThemeProvider theme={theme}>
	      <Container component="main" maxWidth="xs">
		<CssBaseline />	
		  <Navigate to={ECOMM_AUTH_LOGIN_URL+urlParams.current} replace />
	      </Container>
	      </ThemeProvider>)
	}
	
	{ user != null &&  ! user.emailVerified &&
 	    (
	      <ThemeProvider theme={theme}>
	      <Container component="main" maxWidth="xs">
		<CssBaseline />	
	 	  <Navigate to={ECOMM_AUTH_EMAIL_NOT_VERIFIED_URL+urlParams.current} replace />
	        </Container>
	     </ThemeProvider>)
	}
	
	{ user != null && user.emailVerified &&
		(<Outlet />)	  
	}
	</>
    );
    
}

