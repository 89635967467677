// File: create_acct_from_email_link.tsx
//

import * as React from 'react';
import { getAuth } from 'firebase/auth';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';
import { Link, useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import  CircularProgress  from '@mui/material/CircularProgress';
import Backdrop  from '@mui/material/Backdrop';
import { Copyright} from '../../utils_common/copyright';
import {IPageProps} from '../interfaces/page';
import { MsgDialogSimple} from '../../utils_common/msg_dialog';
import { TDBAppLogoLarge } from '../../utils_common/tdb_large_logo';
import { PasswordField} from '../../utils_common/password_field';
import { check_password_strength } from '../../utils_common/password_strength';
//import { signUpWithEmailAndPassword } from '../utils/create_email_password_acct';
import { REG_LOGGER } from '../../utils_common/logger';
import TextField from '@mui/material/TextField';
import { OLDPUB_MOBILE_UPGRADE_CREATE_ACCT_ENDING_URL, MsgStatus, OLDPUB_SIGNIN_ENDING_URL, OLDPUB_EMAIL_VERIFIED_ENDING_URL } from '../../extlinks/portal_comm_types';
import { UseMobUpgradeRoutes } from '../utils/use_mobupgrade_routes';
import { getServerComm } from '../utils/server_comm';
import { useLocation } from 'react-router-dom';
import {  useParams } from 'react-router-dom';
import { get_on_local_host } from '../../config/firebase_config';
import { MOBUPGRADE_URL_TAG } from './email_verified';

const TAG='create_acct_from_email_link.tsx: ';
const DEBUG=false;

const CONFIRM_EMAIL=true;

const theme = createTheme();

/*
async function get_email_from_token(url_token:string) : Promise<string|null> {
    
    const server = getServerComm();
    
    const result = await server.handle_create_acct_from_url_token_get_email({ url_token });

    if(result.status===MsgStatus.ERROR) {
	console.error(TAG+'get_email_from_token: result.status===MsgStatus.ERROR')
	return null;
    }

    console.log(TAG+'get_email_from_token: email=' + result.email);
    return result.email;
}*/


export function CreateAcctFromEmailLink(_props: IPageProps): React.ReactElement {
    
    const cnt =  React.useRef<number>(0);

    React.useEffect( ()=> {    
	if(cnt.current===0) {
	    REG_LOGGER.log_event('ENTER_REGISTER_ACCOUNT','create_acct_from_email_link.tsx')
	}
	cnt.current+=1;
    },[]);


    const is_mobupgrade =  React.useRef<boolean>(false);
    
    const location = useLocation();

    React.useEffect( ()=>{
	console.log(TAG+'location.pathname='+location.pathname);
	console.log(TAG+"MOBILE_UPGRADE_CREATE_ACCT_ENDING_URL="+OLDPUB_MOBILE_UPGRADE_CREATE_ACCT_ENDING_URL);
	if(location.pathname.startsWith(OLDPUB_MOBILE_UPGRADE_CREATE_ACCT_ENDING_URL+'/')) {
	    UseMobUpgradeRoutes.set_on();
	    is_mobupgrade.current = true;
	}
	console.log(TAG+'is_mobupgrade.current='+is_mobupgrade.current);
    },[]);
    
    
    const [ email, setEmail ] = React.useState<string>('');
    
    const [ msg, setMsg ] = React.useState<string>('');

    const [ linkSent, setLinkSent] = React.useState<boolean>(false);
    const [ waiting, setWaiting ] = React.useState<boolean>(false);
    const [ password, setPassword ] = React.useState<string>('');
    const [ submitted, setSubmitted] = React.useState<boolean>(false);
    const [ directToLoginPage, setDirectToLoginPage] = React.useState<boolean>(false);        
    const auth = getAuth();
    const navigate = useNavigate();

    const url_token =  React.useRef<string|null>(null);


    //const email_in_url = React.useRef<string|null>(null);

    const params = useParams();
    
    React.useEffect( ()=>{

	if(url_token.current == null) {
	    if(params.token !== undefined) {
		url_token.current = params.token;
	    }
	}
		    
	if(CONFIRM_EMAIL) return;
	

	/*
	if(url_token.current == null) {
		if(params.token !== undefined) {
		    url_token.current = params.token;
		get_email_from_token(url_token.current).then((result)=>{
		    if(result==null) return;
		    email_in_url.current = result;
		    if( ! CONFIRM_EMAIL) setEmail(result);
		}).catch((error)=>{
		});
	    } else {
		console.error(TAG+'params.token==undefined');
	    }
	}*/
	
    },[]);
    
    const submit_failed = (error:string) : void => {
	REG_LOGGER.error(TAG+'submit_failed: error='+ error);
	setMsg(error);
    }

    const [ authChanged, setAuthChanged ] = React.useState<boolean>(false);

    const start_wait = ():void => {
	let waitInterval = setInterval( ()=> {
	    if(authChanged && waitInterval!=null) {
		// eslint-disable-next-line @typescript-eslint/no-floating-promises		
		if(auth.currentUser) auth.currentUser.reload();		
		clearInterval(waitInterval);
	    }
	    if(auth.currentUser) {
		// eslint-disable-next-line @typescript-eslint/no-floating-promises
		auth.currentUser.reload();
	    }
	}, 2000);
    };
    
    auth.onAuthStateChanged( user=> {
	if(user && user.emailVerified) {
	    setAuthChanged(true);
	}
    });
    
    React.useEffect( () => {
	if(authChanged) {
	    console.log('authchanged')
	    navigate(OLDPUB_SIGNIN_ENDING_URL);	    	    
	}
    }, [authChanged]);
    
    const get_user_status = async ():Promise<number>=> {
	const user = auth.currentUser;
	if(user==null) return 0;
	await user.reload();
	if(user.emailVerified) return 2;
	return 1;
    }

    const signin_and_send_link = ():void => {
	
	if(DEBUG) console.info('entering signin_and_send_link');

	setMsg('');
	setWaiting(true);

	if(url_token.current==null) {
	    console.error(TAG+'signin_and_send_link: url_token.current==null');
	    navigate(OLDPUB_SIGNIN_ENDING_URL);
	    return;
	}

	if(email.trim() === '') {
	    setMsg('Need to input email');
	    return;
	}


	const pw_msg = check_password_strength(password);
	if(pw_msg.trim() !== '') {
	    setMsg(pw_msg);
	    return;
	}
	
	const server = getServerComm();

	let cont_url_ending = OLDPUB_EMAIL_VERIFIED_ENDING_URL+	'/0';
	if(is_mobupgrade.current) {
	    cont_url_ending = 
		OLDPUB_EMAIL_VERIFIED_ENDING_URL+ '/' + MOBUPGRADE_URL_TAG;
	}

	server.handle_create_acct_from_url_token( {
	    url_token:url_token.current,
	    email:email.trim(),
	    password : password.trim(),
	    cont_url_ending,
	    session_is_local:  get_on_local_host(),
	}).then((result)=> {
	    
	    setWaiting(false);
	    
	    if(result.acct_with_email_exists) {

		setMsg('Account with the email already exists.');
		
	    } else if(result.status===MsgStatus.ERROR) {

		setMsg('Server error.');		
		
	    } else {
		setSubmitted(true);
		setPassword('');
		setLinkSent(true);		
		setMsg('Check your email for verification link.');
	    }
	}).catch( (error)=>{
		setWaiting(false);
		submit_failed(error);	    
	});
    }

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
	event.preventDefault();
	//console.log(password);
	//console.log(remember);

	if(linkSent) {
	    setMsg('Email link already sent.');
	    return;
	}

	if(password ==='') {
	    setMsg('Need to input password.')
	    return;
	}
	
	const pw_msg = check_password_strength(password);
	if(pw_msg !== '') {
	    setMsg(pw_msg);
	    return;
	} else {
	    
	    if(DEBUG) console.info('handle_submit: PT1');

	    setDirectToLoginPage(false);	    	    
	    
	    if(email !=='') {
		
		get_user_status().then( (user_status:number):void => {

		    if(user_status === 0)  {
			// not registered as user
			if(submitted) {
			    setMsg('already submitted');
			    setPassword('');			    
			} else {
			    signin_and_send_link();
			    start_wait();
			}
		    } else if(user_status === 1) {
			// registered as user but not email verified
			if(linkSent) {
			    setMsg('Email link already sent.');
			    setPassword('');			    
			} else {
			    setWaiting(false);
			    setSubmitted(true);
			    //send_email_link();
			    start_wait();			    
			}
		    } else {
			// registered as user and email verified
			setMsg('Already have account.');
			setPassword('');			
			setDirectToLoginPage(true);
		    }
		    setPassword('');
		}).catch( (err:string):void=> {
		    REG_LOGGER.error(TAG+'get_user_status: email='+
			email+': returned error=' + err);
		});
	    }
	}
    }
    
    const close_dialog = () : void => {
	setMsg('');
	if(directToLoginPage) {
	    console.log('PT10');
	    setDirectToLoginPage(false);	    
	    navigate(OLDPUB_SIGNIN_ENDING_URL);
	}
    }
    
  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />

      <Backdrop invisible={true} open={waiting} sx={{zIndex : (thme)=>thme.zIndex.drawer + 1}} >
	 <CircularProgress/>
      </Backdrop>
      	
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >

	<Typography align='center'>
         <TDBAppLogoLarge/>
	</Typography>
      
         <Box style={{height:'0.5em'}}/>
      
          <Typography component="h1" variant="h5" sx={{mb:1}}>
            Create Account
          </Typography>
 
          <Box component="form"
	       noValidate onSubmit={(ev:React.FormEvent<HTMLFormElement>)=>handleSubmit(ev)}
	       sx={{ mt: 2 }}>

	{ CONFIRM_EMAIL  &&

	    (<>
		<Typography>
Input the email of your invitation.
		</Typography>

            <TextField
		margin="normal"
		fullWidth
		id="email"
		label="Email Address"
		name="email"
		autoComplete="email"
		autoFocus
		inputProps={{ style: { textTransform: 'lowercase'} }}
		onChange={(e)=> {
		    setMsg('');
		    setEmail(e.target.value.trim().toLowerCase())}}	    
            />
	    
	    </>
	    )
	}
		  
	{ ! CONFIRM_EMAIL  &&	   
            (<Box sx={{mb:2}}>
	      <Typography textAlign='center' variant='h6'>
	      The new account email will be
	      </Typography>
	      <Typography textAlign='center'>
	      {email}
	      </Typography>			      
            </Box>)
	}		  

		<Typography sx={{mb:1,mt:2}} >
               Create a password for the account. 
		</Typography>

	      <PasswordField  id='newpw'
			 fullWidth={true}
			 adornment="New Password"
           		 label="New Password1"
			 password={password}
			 setPassword={setPassword}
			 onChange={()=> {
			     if(msg !== '') setMsg('');
			 }}/>


		<Typography sx={{mb:1,mt:1}} >
(must be at least 8 characters and contain at least one of following: lower-case letter, upper-case letter, number, special character)		  
		</Typography>		
			      
            <Button
		type="submit"
		fullWidth
		variant="contained"
		sx={{ mt: 2, mb: 2 }}
	    >
                  SUBMIT
	    </Button>

	    
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link to={OLDPUB_SIGNIN_ENDING_URL}>
                  Already have an account? Sign in
                </Link>
              </Grid>
            </Grid>
          </Box>
	
        </Box>

	<MsgDialogSimple msg={msg}
			 button_label1={'OK'}
			 service_close={close_dialog}/>
	
        <Copyright sx={{ mt: 5 }} />
      </Container>
    </ThemeProvider>
  );
}

