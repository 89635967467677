// File: ecommtoken_server_comm.ts
//

import {
    MsgStatus,
    ResultType,
    RequestType,
    ResponseType,
    ReqEmailMobileActCode,
    GetUpgradePopupSession,
    CheckLicenseEligibleForMobileUpgrade,
    PopupCreateMobileUpgradeLicense,
    ReqMobUpgradeFSProdURL,
    ECommToken,
} from '../../extlinks/portal_comm_types';

import { getFunctions,  httpsCallableFromURL } from "firebase/functions";
import { get_firebase_functions_portalcomm } from '../../config/firebase_config';

const TIMEOUT=30000;

const TAG='ecommtoken_server_comm.ts: ';
const DEBUG=true;

const PORTAL_FUN_URL=get_firebase_functions_portalcomm();


// for calling onCallable firebase functions
async function query_server<TIn,TOut extends ResultType>(input : TIn,
                                                         cmd:string,
                                                         options? :{cancelExec? : ()=>boolean }) : Promise<TOut|null> {

    const functions = getFunctions();

    const portalFun = httpsCallableFromURL<RequestType<TIn>,ResponseType<TOut>>(
        functions,
        PORTAL_FUN_URL,
        { timeout:TIMEOUT}        
//        {limitedUseAppCheckTokens:true }   // is in beta
    );

    const msg_input:RequestType<TIn> = { cmd:cmd,  input:input }
    
    return new Promise<TOut|null>( (resolve,reject) => {

        if(options && options.cancelExec) {
            setInterval( ()=> {
                if(options && options.cancelExec && options.cancelExec()) {
                    resolve(null);
                }
            }, 5000);
        }
        
        portalFun(msg_input).then( (x) => {
            resolve(x.data.result);
        }).catch( (error) => {
            console.error(TAG+'query_server: error=' + String(error));
            console.error(TAG+'query_server: problems with appcheck for localhost?');                        
            //console.trace();
            //throw new Error(error);
            reject(error);
        });
    });
}

export class ECommTokenServerComm {
    private ECommTokenServerComm_:undefined;
    
    async handle_req_email_mobile_act_code(input:ReqEmailMobileActCode.InType) : Promise<ReqEmailMobileActCode.OutType> {
        
        const out = await query_server<ReqEmailMobileActCode.InType,
                                       ReqEmailMobileActCode.OutType>(input,
                                                                  ReqEmailMobileActCode.CMD);

        if(out == null) {
            return ReqEmailMobileActCode.ERROR_OUT;
        }

        return out;                              
    }


    async handle_req_session_token(input:ECommToken.ReqSessionToken.InType) : Promise<ECommToken.ReqSessionToken.OutType> {
        
        const out = await query_server<ECommToken.ReqSessionToken.InType,
                                       ECommToken.ReqSessionToken.OutType>(input,
                                                                  ECommToken.ReqSessionToken.CMD);

        if(out == null) {
            return ECommToken.ReqSessionToken.ERROR_OUT;
        }

        return out;                              
    }


    async handle_refresh_session_token(input:ECommToken.RefreshSessionToken.InType) : Promise<ECommToken.RefreshSessionToken.OutType> {
        
        const out = await query_server<ECommToken.RefreshSessionToken.InType,
                                       ECommToken.RefreshSessionToken.OutType>(input,
                                                                  ECommToken.RefreshSessionToken.CMD);

        if(out == null) {
            return ECommToken.RefreshSessionToken.ERROR_OUT;
        }

        return out;                              
    }
    
    async handle_req_session_token_is_ok(input:ECommToken.ReqSessionTokenIsOK.InType) : Promise<ECommToken.ReqSessionTokenIsOK.OutType> {
        
        const out = await query_server<ECommToken.ReqSessionTokenIsOK.InType,
                                       ECommToken.ReqSessionTokenIsOK.OutType>(input,
                                                                  ECommToken.ReqSessionTokenIsOK.CMD);

        if(out == null) {
            return ECommToken.ReqSessionTokenIsOK.ERROR_OUT;
        }

        return out;                              
    }

    async handle_desktop_license_eligible_for_mobile_upgrade(input:CheckLicenseEligibleForMobileUpgrade.InType) : Promise<CheckLicenseEligibleForMobileUpgrade.OutType> {

        if(DEBUG) console.log(TAG+'entering handle_desktop_eligible_for_mobile_upgrade');

        const out = await query_server<CheckLicenseEligibleForMobileUpgrade.InType,
                                        CheckLicenseEligibleForMobileUpgrade.OutType>(input,
                                                                  CheckLicenseEligibleForMobileUpgrade.CMD);

        if(DEBUG) console.log(TAG+'handle_desktop_license_eligible_for_mobile_upgrade: out=',out);

        if(out == null) {
            return {
                status : MsgStatus.ERROR,
                is_valid :false,
                error_message:'server error'
            }
        }

        return out;
    }

    async handle_get_encrypted_session(input : GetUpgradePopupSession.InType) : Promise<GetUpgradePopupSession.OutType> {
        if(DEBUG) console.log(TAG+'entering handle_get_encrypted_session');

        const out = await query_server<GetUpgradePopupSession.InType,
                                        GetUpgradePopupSession.OutType>(input,
                                                                  GetUpgradePopupSession.CMD);

        if(DEBUG) console.log(TAG+'handle_get_encrypted_session: out=',out);

        if(out == null) {
            return {
                status : MsgStatus.ERROR,
                encrypted_session : {
                    secured_data : '',
                    secured_key : ''
                }
            }
        }


        return out;
    }


    async handle_popup_create_mobile_upgrade(input:PopupCreateMobileUpgradeLicense.InType) : Promise<PopupCreateMobileUpgradeLicense.OutType> {
        if(DEBUG) console.log(TAG+'entering handle_popup_create_mobile_upgrade');
        const out = await query_server<PopupCreateMobileUpgradeLicense.InType,
                                        PopupCreateMobileUpgradeLicense.OutType>(input,
                                                             PopupCreateMobileUpgradeLicense.CMD);

        if(DEBUG) console.log(TAG+'handle_popup_create_mobile_upgrade: out=',out);

        if(out == null) {
            return {
                status : MsgStatus.ERROR,
                is_valid:false,
                error_message:'server error',
                cancel_order_status:PopupCreateMobileUpgradeLicense.CancelOrderStatus.ORDER_ATTEMPTED_TO_CANCEL_BUT_FAILED
            }
        }

        return out;
    }


    async handle_get_mob_upgrade_prod_url(input:ReqMobUpgradeFSProdURL.InType) : Promise<ReqMobUpgradeFSProdURL.OutType> {
        if(DEBUG) console.log(TAG+'entering handle_popup_create_mobile_upgrade');
        const out = await query_server<ReqMobUpgradeFSProdURL.InType,
                                        ReqMobUpgradeFSProdURL.OutType>(input,
                                                             ReqMobUpgradeFSProdURL.CMD);

        if(DEBUG) console.log(TAG+'handle_get_mob_upgrade_prod_url: out=',out);

        if(out == null) {
            return {
                status : MsgStatus.ERROR,
                fs_prod_url: ''
            }
        }

        return out;
    }

    async handle_req_landing_page_type(input:ECommToken.ReqLandingPageType.InType) : Promise<ECommToken.ReqLandingPageType.OutType> {
        if(DEBUG) console.log(TAG+'entering handle_req_landing_page_type');
        const out = await query_server<ECommToken.ReqLandingPageType.InType,
                                                             ECommToken.ReqLandingPageType.OutType>(input,
                                                             ECommToken.ReqLandingPageType.CMD);

        if(DEBUG) console.log(TAG+'handle_get_mob_upgrade_prod_url: out=',out);

        if(out == null) {
            return ECommToken.ReqLandingPageType.ERROR_OUT;
        }

        return out;
    }

    async handle_req_license_keys(input:ECommToken.ReqLicenseKeys.InType) : Promise<ECommToken.ReqLicenseKeys.OutType> {
        if(DEBUG) console.log(TAG+'entering handle_req_license_keys');
        const out = await query_server<ECommToken.ReqLicenseKeys.InType,
                                                             ECommToken.ReqLicenseKeys.OutType>(input,
                                                             ECommToken.ReqLicenseKeys.CMD);

        if(DEBUG) console.log(TAG+'handle_req_license_keys: out=',out);

        if(out == null) {
            return ECommToken.ReqLicenseKeys.ERROR_OUT;
        }

        return out;
    }

    async handle_req_user_licenses_info(input:ECommToken.ECommGetUserLicensesInfo.InType) : Promise<ECommToken.ECommGetUserLicensesInfo.OutType> {
        if(DEBUG) console.log(TAG+'entering handle_req_user_licenses_info');
        const out = await query_server<ECommToken.ECommGetUserLicensesInfo.InType,
                                                             ECommToken.ECommGetUserLicensesInfo.OutType>(input,
                                                             ECommToken.ECommGetUserLicensesInfo.CMD);

        if(DEBUG) console.log(TAG+'handle_req_user_licenses_info: out=',out);

        if(out == null) {
            return ECommToken.ECommGetUserLicensesInfo.ERROR_OUT;
        }

        return out;
    }

    async handle_check_ecomm_url_params_and_session_token(input:ECommToken.CheckUrlParamsAndSessionToken.InType) : Promise<ECommToken.CheckUrlParamsAndSessionToken.OutType> {
        if(DEBUG) console.log(TAG+'entering handle_check_ecomm_url_params_and_session_token');
        const out = await query_server<ECommToken.CheckUrlParamsAndSessionToken.InType,
                                                             ECommToken.CheckUrlParamsAndSessionToken.OutType>(input,
                                                             ECommToken.CheckUrlParamsAndSessionToken.CMD);

        if(DEBUG) console.log(TAG+'handle_get_mob_upgrade_prod_url: out=',out);

        if(out == null) {
            return ECommToken.CheckUrlParamsAndSessionToken.ERROR_OUT;
        }

        return out;
    }        

    async handle_check_ecomm_url_params(input:ECommToken.CheckUrlParams.InType) : Promise<ECommToken.CheckUrlParams.OutType> {
        if(DEBUG) console.log(TAG+'entering handle_check_ecomm_url_params');
        const out = await query_server<ECommToken.CheckUrlParams.InType,
                                                             ECommToken.CheckUrlParams.OutType>(input,
                                                             ECommToken.CheckUrlParams.CMD);

        if(DEBUG) console.log(TAG+'handle_get_mob_upgrade_prod_url: out=',out);

        if(out == null) {
            return ECommToken.CheckUrlParams.ERROR_OUT;
        }

        return out;
    }        

}
 
export function getECommTokenServerComm() : ECommTokenServerComm {
    return new ECommTokenServerComm();     
}
