// File: send_mobupgrade_invites.tsx
//

import * as React from 'react';
import { useNavigate} from 'react-router-dom';
import { MainLayout} from '../utils_admin/main_layout';
import { useAdminPagesContext } from '../utils_admin/admin_pages_provider';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
//import Backdrop  from '@mui/material/Backdrop';
//import  CircularProgress  from '@mui/material/CircularProgress';
import { get_session_token, LOGGER} from '../../utils_common/logger';
import { getAdminServerComm } from '../utils_admin/admin_server_comm';
import { GetMobUpgradeableLicenses, AdminRole,
    MsgStatus,
    ADMIN_HOME_URL,
    ADMIN_LIST_MOBUPGRADE_SEARCH_RESULTS_URL
} from '../../extlinks/portal_comm_types';
import { useLocationState, sendLocationState } from '../../utils_common/use_location_state';
import { OKDialog } from '../utils_admin/ok_cancel_dialog';
import {MobUpgradeableLicensesTable,
    ListAllLicensesLocState
} from '../utils_admin/mobupgrade_invites/mobupgradeable_licenses_table';
import { get_row_filter, RowFilter, NONE_ROW_FILTER } from '../utils_admin/mobupgrade_invites/select_filter_rows';
import { SelectLicenseSorter, get_license_sorter,
    EMAIL_SORTER,LicenseSorter } from '../utils_admin/mobupgrade_invites/select_license_sorter';
import { decompressFromBase64 } from '../../utils_common/lzstring';
import {  get_search_licenses_results_state, BackLicensesTableState,
    SearchLicensesResultState} from '../utils_admin/mobupgrade_invites/licenses_loc_states';
import { DownloadProgressDialog } from '../../utils_common/alert_dialog';

const TAG='send_mobupgrade_invites.tsx: '
const DEBUG=true;
 
const THEME = createTheme();

import LicenseInfo = GetMobUpgradeableLicenses.ParentLicenseInfo;

function add_to_mp_to_list(mp: Map<string,string[]>,key:string, entry:string) : void {
    const x = mp.get(key);
    if(x === undefined) {
	mp.set(key,[entry]);
    } else {
	x.push(entry);
    }
}

function get_email_to_license_keys(licenses: LicenseInfo[]) : Map<string, string[]> {
    
    const mp = new Map<string,string[]>();
    for(let x of licenses) {
	for(let y of  x.ac_emails) add_to_mp_to_list(mp,y, x.desktop_license_key);
	for(let y of  x.fs_emails) add_to_mp_to_list(mp,y, x.desktop_license_key);
	for(let y of  x.as_emails) add_to_mp_to_list(mp,y, x.desktop_license_key);
	if(x.portal_email) add_to_mp_to_list(mp, x.portal_email, x.desktop_license_key);
    }
    
    return mp;    
}
async function get_num_upgradeable_licenses() : Promise<number|null> {

    const server = getAdminServerComm();
    const session_token = get_session_token();
    
    const result = await server.handle_get_mobupgrade_licenses({
	session_token,
	min_range: 0,
	max_range: 0,
    });

    if(result.status === MsgStatus.OK) {
	return  result.num_upgradeable_licenses;
    } else {
	console.error(TAG+'get_num_upgradeable_licenses: error: error_msg='+result.error_msg);
	return null;
    }
}

async function get_partial_license_infos(args:{min_range:number,max_range:number,
                                               cancelExec : ()=>boolean}) : Promise<LicenseInfo[]|null> {

    //console.log(TAG+`get_partial_license_infos: min_range: ${args.min_range}, max_range: ${args.max_range}`);    
    
    const server = getAdminServerComm();
    const session_token = get_session_token();
    
    const result = await server.handle_get_mobupgrade_licenses({
	session_token,
	min_range: args.min_range,	    
	max_range: args.max_range,
    }, { cancelExec : args.cancelExec} );

    if(result.status === MsgStatus.OK) {
	console.log(TAG+'result.parent_license_infos_compress_b64.length=' +
	    result.parent_license_infos_compress_b64.length);
	const parent_license_infos_json = decompressFromBase64(result.parent_license_infos_compress_b64);
	if(parent_license_infos_json==null) {
	    console.error('parent_license_infos_json==null');
	    return null;
	}
	const parent_license_infos = JSON.parse(parent_license_infos_json) as LicenseInfo[];
	if(DEBUG) console.log(TAG+'refresh: result.user_licenses_in_range.length='+
	    parent_license_infos.length);
	//if(DEBUG) console.log(TAG+`leaving get_partial_license_infos: min_range: ${args.min_range}, max_range: ${args.max_range}`);    	
	return  parent_license_infos;
    } else {
	console.error(TAG+'error: error_msg='+result.error_msg);
	LOGGER.error(result.error_msg.replace(/\n/g,''));
	return null;
    }
    
}
/*
async function
get_license_infos(args : { licenseSorter : LicenseSorter, rowFilter : RowFilter }) : Promise<LicenseInfo[]> {
	
    //const nsize = 1000;
    const nsize = 500;    

    let min_range = 0;

    let out : LicenseInfo[] = [];
    
    while(1) {

	const max_range = min_range + nsize;
	
	console.log(TAG+`get_license_info: min_range: ${min_range}, max_range: ${max_range}`);
	
	const infos = await get_partial_license_infos({min_range,max_range});

	if(infos==null) {
	    console.error(`min_range: ${min_range}, max_range: ${max_range}: infos==null`);
	} else {

	    console.log(TAG+'get_license_infos: infos.length='+infos.length);
	    
	    if(infos.length===0) break;
	    
	    if(infos.length < nsize) {
		out = out.concat(infos);
		break;
	    }

	    out = out.concat(infos);
	}

	min_range += nsize;
    }

    console.log(TAG+'get_license_infos: out.length='+out.length);
    console.log(TAG+'get_license_infos: rowFilter='+args.rowFilter.name);
    
    out = args.licenseSorter.sort(out).filter((x)=>args.rowFilter.filter(x));					      
					      
    return out;
}
*/

async function
get_license_infos_promise_all(args : { licenseSorter : LicenseSorter,
                                       rowFilter : RowFilter,
                                       cancelExec : ()=>boolean}) : Promise<LicenseInfo[]> {

    const num_licenses = await get_num_upgradeable_licenses();

    if(num_licenses==null) {
	console.error(TAG+'get_license_infos_promise_all: num_licenses==null');
	return [];
    }

    //const nsize = 1000;
    const nsize = 500;

    let min_range = 0;

    const promises : (Promise<LicenseInfo[]|null>)[] = [];

    
    
    while(1) {

	const max_range = min_range + nsize;

	if(min_range >= num_licenses) break;
	
	promises.push( new Promise<LicenseInfo[]|null>( (resolve) => {
	    get_partial_license_infos({min_range, max_range, cancelExec : args.cancelExec}).then( (result)=> {
		resolve(result);
	    }).catch( (error)=> {
		console.log('get_license_infos_promise_all: error='+error);
		resolve(null);
	    });
	}));

	min_range += nsize;
    }

    // eslint-disable-next-line no-unreachable
    const out_array = await Promise.all(promises);

    let out : LicenseInfo[] = [];
    for(let x of out_array) {
	if(x != null)  out = out.concat(x);
    }
    
    console.log(TAG+'get_license_infos: out.length='+out.length);
    console.log(TAG+'get_license_infos: rowFilter='+args.rowFilter.name);
   
				      
    return out;
}


export function SendMobUpgradeInvitesPage() : React.ReactElement {

    const page_num_ref = React.useRef<number>(0);

    // this has subsidiary effect of forcing a re-render of the table
    // upon page change from a page number link
    // (otherwise wont re-render because url is unchangd)
    const [ pageNum, setPageNum] = React.useState<number|null>(null);

    const loc_state = useLocationState<ListAllLicensesLocState>();
  
    const [ openServerErrorDialog, setOpenServerErrorDialog] = React.useState<boolean>(false);
    
    const [ openNoResultsDialog, setOpenNoResultsDialog] = React.useState<boolean>(false);
    
    const [ openDownloadingDialog, setOpenDownloadingDialog ] = React.useState<boolean>(false);
	
    const [ numPerPage, setNumPerPage ] = React.useState<number>(100);

    const [ rowFilter, setRowFilter ] = React.useState<RowFilter>(NONE_ROW_FILTER);

    const [ licenseSorter, setLicenseSorter ] = React.useState<LicenseSorter>(EMAIL_SORTER);

    const cancel = React.useRef<boolean>(false);

    const setCancel = ():void=> {
	cancel.current=true;
    }
    
    const mainStateContext = useAdminPagesContext();
    const mainPagesIsLoading = mainStateContext.is_loading;

    const [ isWaiting,setIsWaiting] = React.useState<boolean>(false);

    const navigate = useNavigate();
    
    React.useEffect(()=> {

	//setOpenDownloadingDialog(false);
	
	console.log(TAG+'loc_state=',loc_state);
	
        if(mainStateContext.current_user_admin_role !== AdminRole.MANAGER &&
            mainStateContext.current_user_admin_role !== AdminRole.EDITOR) {
            navigate(ADMIN_HOME_URL);
        }
    },[])
    
    const [filteredLicenseInfos, setFilteredLicenseInfos ] = 
	React.useState<GetMobUpgradeableLicenses.ParentLicenseInfo[]|null>(null);
    
    const [allLicenseInfos, setAllLicenseInfos ] = 
	React.useState<GetMobUpgradeableLicenses.ParentLicenseInfo[]|null>(null);    

    React.useEffect(()=> {
	setPageNum(0);
    },[rowFilter]);

    React.useEffect(()=> {
	if(allLicenseInfos) {
	    const y = licenseSorter.sort(allLicenseInfos).filter((x)=>rowFilter.filter(x));
	    setFilteredLicenseInfos(y);
	}
    },[allLicenseInfos,rowFilter, licenseSorter]);


    const set_filter_sorter_from_back_state = ():void=> {

	console.log(TAG+'loc_state=',loc_state);
	
	if(loc_state && 'BackLicensesTableState_' in loc_state) {
	    console.log(TAG+'set_filter_sorter_from_back_state');
	    const state = loc_state as BackLicensesTableState;
	    if(state.rowFilterName && state.licenseSorterName) {
		const row_filter = get_row_filter(state.rowFilterName);
		const license_sorter = get_license_sorter( state.licenseSorterName);
		if(row_filter && license_sorter) {
		    setRowFilter(row_filter);
		    setLicenseSorter(license_sorter);
		}
	    }
	    //setPageNum(loc_state.page_num);
	}	
    }

    React.useEffect(()=> {
	set_filter_sorter_from_back_state();	
    },[]);
	    
    const refresh_cnt = React.useRef<number>(0);    


    const cancelExec = () : boolean => {
	//console.log(TAG+'cancelExec: cancel='+cancel.current);
	return cancel.current;
    }
    
    const refresh = (force:boolean=false):void => {

	if( ! force && refresh_cnt.current !== 0) return;
	refresh_cnt.current++;
	
	if(DEBUG) console.log(TAG+'***entering refresh');

	setIsWaiting(true);
	setOpenDownloadingDialog(true);		
	get_license_infos_promise_all({rowFilter, licenseSorter, cancelExec}).then( (license_infos) => {
	    setIsWaiting(false);
	    setOpenDownloadingDialog(false);
	    setAllLicenseInfos(license_infos);
	    if(allLicenseInfos) {
		const y = [...licenseSorter.sort(license_infos).filter((x)=>rowFilter.filter(x))];
		setFilteredLicenseInfos(y);
	    }
	}).catch( (error)=> {
	    setIsWaiting(false);
	    setOpenDownloadingDialog(false);	    
	    LOGGER.error(TAG+'refresh: error='+error);
	});
    }

    const cnt = React.useRef<number>(0);

    React.useEffect(()=> {
	if(cnt.current===0) {
	    LOGGER.log_event('LIST ALL LICENSES','list_all_licenses.tsx');
	}
	cnt.current++;
    },[]);

//send-mobupgrade-invites
    
    // TODO: need refresh button to account for changes in license from last time
    React.useEffect(()=> {
	//console.log('page_num='+page_num.current);
 	if(loc_state && loc_state.page_num !== undefined) {
	    const x = loc_state.page_num; // this prevents need for double click
	    setPageNum(x);  // this is needed to cause table to re-render
	    page_num_ref.current = loc_state.page_num;	    
	    if(DEBUG) console.log(TAG+'loc_state.page_num=',loc_state.page_num);
	    if(DEBUG) console.log(TAG+'PT0 set page_num=',pageNum);
	} else {
	    setPageNum(0); // this is needed to cause table to re-render
	    page_num_ref.current = 0;
	    if(DEBUG) console.log(TAG+'PT1 set page_num=',pageNum);
	}

	if(loc_state && loc_state.allValidUserLicenses !== undefined &&
	     loc_state.allValidUserLicenses.length>0) {
	    if(DEBUG) console.log(TAG+'PT3 loc_state.allValidUserLicenses.length='+
		loc_state.allValidUserLicenses.length);
	    console.log(TAG+'rowFilter='+rowFilter.name);
	    setIsWaiting(true);
	    console.log(TAG+'before sort');
	    const w = [...loc_state.allValidUserLicenses];
	    setAllLicenseInfos(w);
	    if(allLicenseInfos) {
		const y = [...licenseSorter.sort(w).filter((x)=>rowFilter.filter(x))];
		setFilteredLicenseInfos(y);
	    }	    
	    console.log(TAG+'after sort');	    
	    setIsWaiting(false);
	} else if(allLicenseInfos!=null) {
	    const y = [...licenseSorter.sort(allLicenseInfos).filter((x)=>rowFilter.filter(x))];
	    setFilteredLicenseInfos(y);
	} else {
	    refresh();
	}

    },[loc_state?.page_num, licenseSorter, rowFilter])
	
    const [ searchExpr, setSearchExpr ] = React.useState<string>('');
    
    const getAllEmailToLicenseKeys = () : Map<string,string[]>|null=> {

	if(allLicenseInfos == null) {
	    setOpenServerErrorDialog(true);
	    return null;
	}
	
	const licenses: LicenseInfo[]|null = allLicenseInfos;
	if(! licenses) return null;
	
	const mp = get_email_to_license_keys(licenses);

	//console.log(TAG+'email_to_license_key.keys()=' + Array.from(mp.keys()));
	
	return mp;
    }

    const get_license_keys_of_email_matches = (expr:string) :string[]|null=> {
	
	const email_to_license_keys = getAllEmailToLicenseKeys();

	if(email_to_license_keys == null) return null;

	const license_keys_set = new Set<string>();
	
	const ws = expr.split(' ').map( (x)=>x.trim().toLowerCase() );
	for(let w of ws) {
	    if(w !== '') {
		for(let [email,license_keys] of email_to_license_keys) {
		    if(email.includes(w)) {
			for(let license_key of license_keys) {
			    license_keys_set.add(license_key);
			}
		    }
		}
	    }
	}

	return Array.from(license_keys_set);
    }

    const setRowFilterX = (filter:RowFilter):void => {
	console.log(TAG+' setRowFilterX: filter.name='+filter.name);
	setRowFilter(filter);
	// refresh();//!!!!
	if(allLicenseInfos) {
	    const y = [...licenseSorter.sort(allLicenseInfos).filter((x)=>rowFilter.filter(x))];
	    setFilteredLicenseInfos(y);
	    if(filteredLicenseInfos) console.log(TAG+'filteredLicenseInfo.length='+filteredLicenseInfos.length);
	}	    		
    }

    const searchEmails = ():void => {

	setOpenNoResultsDialog(false);
	
	const expr = searchExpr.trim();
	if(expr === '') return;

	setIsWaiting(true);

	const matching_license_keys = get_license_keys_of_email_matches(expr);

	console.log(TAG+'matching_license_keys=',matching_license_keys);
	
	if(matching_license_keys==null || matching_license_keys.length===0) {
	    setIsWaiting(false);
	    setOpenNoResultsDialog(true);	    
	    return;
	}

	//console.log('matching_license_keys=',matching_license_keys); 
	
	if(!allLicenseInfos) {
	    setOpenServerErrorDialog(true);	    
	    return;
	}

	const matching_licenses : LicenseInfo[] = []
	
	for(let license_key of matching_license_keys) {
	    for(let x of allLicenseInfos) {
		if(x.desktop_license_key === license_key) {
		    matching_licenses.push(x);
		}
	    }
	}

	setIsWaiting(false);
	
	if(matching_licenses.length>0) {
	    const state : SearchLicensesResultState =
		get_search_licenses_results_state({
		    page_num : pageNum,
		    matching_licenses,
		    rowFilterName:rowFilter==null ? null : rowFilter.name,
		    licenseSorterName : licenseSorter==null ? null : licenseSorter.name,
		    allUserLicenses : allLicenseInfos,
		});
	    sendLocationState<SearchLicensesResultState>(ADMIN_LIST_MOBUPGRADE_SEARCH_RESULTS_URL,
		state, navigate);
	    //navigate(ADMIN_LIST_MOBUPGRADE_SEARCH_RESULTS_URL, {state: {matching_licenses}} );
	}
    }

    React.useEffect( ()=> {
	console.log(TAG+'rowFilter='+rowFilter.name);
	console.log(TAG+'sorter='+licenseSorter.name);
	//refresh(); //!!!!
	if(allLicenseInfos) {
	    const y = [...licenseSorter.sort(allLicenseInfos).filter((x)=>rowFilter.filter(x))];
	    setFilteredLicenseInfos(y);
	}
	if(filteredLicenseInfos) {
	    console.log(TAG+'filteredLicenseInfos.length=' + filteredLicenseInfos.length);
	}
    },[rowFilter,licenseSorter]);
    
    return (
	<ThemeProvider theme={THEME}>	
	    <CssBaseline />	      
	    <MainLayout title='Admin Console - Send Mobile Upgrade Invitations'>

	<>
	{filteredLicenseInfos &&		
	    (<><Box sx={{
		    display: 'flex', flexDirection:'column',
 		    alignItems : 'center'}}>
	      
	      <Box><h2>Desktop Licenses Eligible for Mobile Upgrade</h2></Box>
	      
	      <Box
		sx={{paddingLeft:'0',ml:0, mb:2,
		    display: 'flex', flexDirection:'row',
 		    alignItems : 'center',
		    justifyContent:'flex-end'}}>

		<TextField
		sx={{mt:0,mb:0}}
		size="small"
		id="email"
		label=""
		name="email"
		autoFocus
		inputProps={{ style: { textTransform: 'lowercase'} }}
		onChange={(e)=> {
		    setSearchExpr(e.target.value.trim().toLowerCase())
		}}
		/>

		<Button  onClick={searchEmails}
			 size="small" sx={{ml:3, border:'solid'}}>
	      SEARCH EMAIL
		</Button>
	</Box>

	</Box>	
	    </>)
	}
	</>

	  
	<>
       	{/* change in pageNum causes table to automatically re-render */}
	{allLicenseInfos && filteredLicenseInfos &&  pageNum != null &&

	    (<>
	      
	      <Box sx={{mt:1}}>
		<SelectLicenseSorter
		    setSorter={setLicenseSorter}
		    sorter={licenseSorter}
		    onChange={()=>{}}/>
	      </Box>
	      
	      <MobUpgradeableLicensesTable pageNum={pageNum}
					   setPageNum={setPageNum}
					   split_pages={true}
					   numPerPage={numPerPage}
					   setNumPerPage={setNumPerPage}
					   rowFilter={rowFilter}
					   licenseSorter={licenseSorter}
					   setRowFilter={setRowFilterX}
					   onFilterChange={()=>{}}
					   refreshContents={()=>refresh(true)}
					   allLicenseInfos={allLicenseInfos}
   					   filteredLicenseInfos={filteredLicenseInfos}/>
		</>)
	}	      
	</>

	{/*
	<Backdrop invisible={true} open={mainPagesIsLoading || isWaiting}
		  sx={{zIndex : (thme)=>thme.zIndex.drawer + 1}} >
	  <CircularProgress/>
	</Backdrop>
	*/}

	<>
	{ (mainPagesIsLoading || isWaiting) &&
	    <DownloadProgressDialog msg={'Downloading data. This may take a few minutes.'} setCancel={setCancel}/>
	}
	</>
	

	<OKDialog onClose={()=>setOpenNoResultsDialog(false)}
		  open={openNoResultsDialog} title='No results found.'/>
	
	<OKDialog onClose={()=>setOpenServerErrorDialog(false)}
		  open={openServerErrorDialog} title='Server error. Try again by refreshing page.'/>	  

	{/*
	<OKDialog onClose={()=>setOpenDownloadingDialog(false)}
		  open={openDownloadingDialog} title='Download data. This may take a few minutes.'/>
	*/}
	  
	    </MainLayout>

	</ThemeProvider>
  );
    
}
