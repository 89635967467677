// File: routes_ecomm_auth.tsx
//

import * as React from 'react';
import {Route} from "react-router-dom";
import { ECOMM_AUTH_LICENSE_INFO_URL,
    ECOMM_AUTH_MOBUPGRADE_LANDING_URL,
    ECOMM_AUTH_DISPLAY_LICENSE_KEYS_LANDING_URL,
    ECOMM_AUTH_PUB_LANDING_URL,
    ECOMM_AUTH_PRIVATE_LANDING_URL,
    ECOMM_AUTH_LOGIN_URL,
    ECOMM_AUTH_NOT_FOUND_URL,
    ECOMM_AUTH_EMAIL_LINK_LANDING_URL,
    ECOMM_AUTH_EMAIL_NOT_VERIFIED_URL,
    ECOMM_AUTH_MOBILE_INSTALL_INSTRUCT_URL,
ECOMM_AUTH_DESKTOP_INSTALL_INSTRUCT_URL    
       } from "../extlinks/portal_comm_types";
import { ECommAuthMobileUpgradeLanding } from "../pages_ecomm_auth/private/mobile_upgrade_landing";
import { ECommAuthDisplayLicenseKeysLanding } from "../pages_ecomm_auth/private/display_license_keys_landing";
import { ECommAuthLicenseInfoLanding } from "../pages_ecomm_auth/private/license_info_landing";
import { ECommAuthDesktopInstallInstructions } from '../pages_ecomm_auth/public/desktop_install';
import { ECommAuthMobileInstallInstructions} from '../pages_ecomm_auth/public/mobile_install';
import { ECommAuthRequireAuth } from "../pages_ecomm_auth/ECommAuthRequireAuth";
import { ECommAuthLogin } from '../pages_ecomm_auth/public/ecomm_auth_login';
import { ECommAuthEmailLinkLanding } from "../pages_ecomm_auth/public/email_link_landing";
import { ECommAuthNotFound } from "../pages_ecomm_auth/public/ecomm_not_found";
import { ECommAuthProvider } from "../pages_ecomm_auth/ecomm_auth_provider";
import { ECommAuthEmailNotVerified } from '../pages_ecomm_auth/public/ecomm_auth_email_not_verified';
import { ECommAuthPubLanding } from '../pages_ecomm_auth/public/pub_landing';
import { ECommAuthPrivateLanding } from '../pages_ecomm_auth/private/private_landing';

export function ECommAuthRoutes() : React.ReactElement {
    return (
	<>
	  
	<Route path={ECOMM_AUTH_NOT_FOUND_URL}
	       element={<ECommAuthNotFound/>}
	/>

	<Route path={ECOMM_AUTH_EMAIL_NOT_VERIFIED_URL}
	       element={<ECommAuthEmailNotVerified/>}
	/>	

	<Route path={ECOMM_AUTH_LOGIN_URL+'/:url_params'}
	       element={<ECommAuthLogin />}
	/>

	<Route path={ECOMM_AUTH_LOGIN_URL}
	       element={<ECommAuthLogin />}
	/>	
	
	<Route path={ECOMM_AUTH_EMAIL_LINK_LANDING_URL+'/:url_params'}
	       element={<ECommAuthEmailLinkLanding/>}
	/>
	
	<Route path={ECOMM_AUTH_PUB_LANDING_URL+'/:url_params'}
	       element={<ECommAuthPubLanding/>}
	/>
	  
	<Route path={ECOMM_AUTH_DESKTOP_INSTALL_INSTRUCT_URL+'/:url_params'}
	       element={<ECommAuthDesktopInstallInstructions/>}
	/>
	  
	<Route path={ECOMM_AUTH_MOBILE_INSTALL_INSTRUCT_URL+'/:url_params'}
	       element={<ECommAuthMobileInstallInstructions/>}
	/>	
	
	<Route element={<ECommAuthProvider>
	                   <ECommAuthRequireAuth/>
       	                </ECommAuthProvider>} >

	
	  <Route path={ECOMM_AUTH_PRIVATE_LANDING_URL+'/:url_params'}
		 element={<ECommAuthPrivateLanding/>}
	  />	  
	  
  	    <Route path={ECOMM_AUTH_MOBUPGRADE_LANDING_URL+'/:url_params'}
		 element={<ECommAuthMobileUpgradeLanding/>}
	    />

	    <Route path={ECOMM_AUTH_DISPLAY_LICENSE_KEYS_LANDING_URL+'/:url_params'}
		 element={<ECommAuthDisplayLicenseKeysLanding/>}
	    />
	  
	    <Route path={ECOMM_AUTH_LICENSE_INFO_URL+'/:url_params'}
		 element={<ECommAuthLicenseInfoLanding/>}
	     />
	    
	</Route>
	
	</>
    );
}
