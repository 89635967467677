// File: register_email.ts
//

//import {getAuth, createUserWithEmailAndPassword } from 'firebase/auth';
import { getServerComm } from './server_comm';
import { REG_LOGGER } from '../../utils_common/logger';
import { MsgStatus, OLDPUB_EMAIL_VERIFIED_ENDING_URL } from '../../extlinks/portal_comm_types';
import { assertUnreachable } from '../../utils_common/assert_unreachable';
import { get_on_local_host } from '../../config/firebase_config';

const TAG='create_email_password_acct.ts: ';
const DEBUG=true;


function authCreateUserWithEmailAndPassword(props : {email:string,
                                                     password:string,
                                                     url_tag:string,
                                                     account_is_created : ()=>void,
                                                    },
                                            fail : (error:string)=>void) : void {
    const server = getServerComm();

    const session_is_local = get_on_local_host();
    const cont_url_ending = OLDPUB_EMAIL_VERIFIED_ENDING_URL +'/' + props.url_tag;
    
    server.handle_create_new_user_acct({email:props.email,
                                        password:props.password,
                                        session_is_local,
                                        cont_url_ending,
                                       }).
        then((result)=> {
            const tag=TAG+'handle_create_new_user_acct: ';
            if(result.status === MsgStatus.ERROR) {
                if(result.error_status==null) {
                    console.error(tag+'result.error_status=null');
                    fail('Internal Error (code=0)')
                } else {
                    console.error(tag+'result.error_status='+result.error_status);
                    if(result.error_msg) {
                        console.error(tag+`result.error_msg="${result.error_msg}"`);                        
                    }
                    switch(result.error_status) {
                        case  'ERROR_CREATING_ACCT':
                            fail('Error creating account.');
                            return;
                        case 'EMAIL_ALREADY_IN_USE':
                            fail('Account with that email already exists.');                            
                            return;
                        case 'INIT_PASSWORD_NOT_CORRECT':
                            fail('Must use password in the invitation message.');
                            return;
                        case 'EMAIL_NOT_ELIGIBLE':
                            fail('Email is not eligible for an account');
                            return;
                        case 'SERVER_ERROR':
                            fail('Server Error');
                            return;
                        case 'SERVER_INTERNAL_ERROR':
                            fail('Server Internal Error');
                            return;                            
                        case 'INTERNAL_ERROR':
                            fail('Internal Error (code=1)');
                            return;                            
                        case 'ERROR_SENDING_EMAIL_LINK':
                            fail('Internal Error (code=2)');
                            return;                            
                    }
                    assertUnreachable(result.error_status);
                }
                
            } else {
                
                props.account_is_created();
                
            }
        }).
        catch( (error)=> {
            const tag=TAG+'handle_create_new_user_acct: ';            
            console.error(tag+'caught error='+error);            
        });
}

function check_email_eligibility(props : {email:string,
                                          password:string,
                                          email_is_eligible: ()=>void,
                                         },
                                 fail : (error:string)=>void) : void {

    if(DEBUG) {
        console.info('entering check_email_eligibility');
    }

    const server = getServerComm();

    server.handle_get_new_email_is_eligible({ email:props.email }).then( (x) =>  {

        const tag = TAG+'server.handle_get_new_email_is_eligible: ';

        if(x.status !== 'OK') {
            
            REG_LOGGER.error(tag+'server error: status='+x.status);                            
            fail('Server error.');
            return;
            
        }
        switch(x.email_status) {

            case 'ELIGIBLE': {
                
                REG_LOGGER.info(tag+'returned eligible');

                props.email_is_eligible();

                return;
            }
                
            case 'EMAIL_NOT_ELIGIBLE': {

                REG_LOGGER.info(tag+'returned in-eligible');                
                
                fail("Sorry, we don't have a record of this email and password.");

                return;
            }

            case 'EMAIL_ALREADY_IN_USE': {

                REG_LOGGER.info(tag+'licenses already claimed: email='+props.email);
                
                fail('This email has already been used.');

                return;                
            }

            case 'SERVER_INTERNAL_ERROR': {
                REG_LOGGER.info(TAG+'signUpWithEmailAndPassword:handle_get_new_email_is_eligible: server internal error: email='+props.email);
                fail('Server Internal Error.');
                return;
            }

            case 'SERVER_ERROR': {
                REG_LOGGER.info(TAG+'signUpWithEmailAndPassword:handle_get_new_email_is_eligible: server error: email='+props.email);
                fail('Server Error.');
                return;
            }                                                                         
        }

        assertUnreachable(x.email_status);
        
    }).catch( (errorx):void => {

        REG_LOGGER.error(TAG+'signUpWithEmailAndPassword:handle_get_new_email_is_eligible: errorx='+
            String(errorx));
	fail('Server error, try later.');        
    });
    
}

function check_password_before_acct(props : {email:string,
                                             password:string,
                                             password_ok:()=>void
                                            },
                                          fail : (error:string)=>void) : void {
    const server = getServerComm();
    
    server.handle_check_password_before_acct({ email:props.email, password:props.password}).then(
        (result)=> {
            if(result.status===MsgStatus.ERROR) {
                fail('Server error, try later.');
            } else if( ! result.password_is_ok) {
                fail('Initial password must be the one sent to you.');                
            } else {
                props.password_ok();
            }
        }
    ).catch( (error)=> {

        REG_LOGGER.error(TAG+'check_email_password_before_acct: error='+
            String(error));
	fail('Server error, try later.');
        
    });
}


//
// add new email and password to firebase auth
//
// (internally calls firebase function userEmailIsRegistered()
// that will check if the email
// corresponds to a purchase account; if not, the account
// will be immediately deleted)
//
// account_is_created() is called iff the email is associated with
// a purchase
//
// normally account_is_created() will call sendSigninLinkEmail() above
// to send an email verification link
//
export function
signUpWithEmailAndPassword(props : {email:string,
                                    password:string,
                                    url_tag:string,
                                   },
                           account_is_created : ()=>void,
                           fail : (error:string)=>void) : void {

//    REG_LOGGER.info('entering signUpWithEmailAndPassword: email='+props.email);
    
    if(DEBUG) {
        console.info('entering signUpWithEmailAndPassword');
    }

    const email_is_eligible=():void=> {

        const password_ok=():void=> {
            console.info(TAG+'entering password_is_ok');            
            authCreateUserWithEmailAndPassword({...props, account_is_created}, fail);            
        }
        
        check_password_before_acct({...props, password_ok}, fail);
        
    }

    check_email_eligibility({...props, email_is_eligible}, fail);
    
}

