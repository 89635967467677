// File: routes_admin.tsx
//

import * as React from 'react';
import {Route} from "react-router-dom";
import { AdminRequireAuth } from "../pages_admin/AdminRequireAuth";
import { AdminPagesProvider } from '../pages_admin/utils_admin/admin_pages_provider';
import { AdminHome } from '../pages_admin/auth_pages/admin_home';
import { ListAllLicenses } from "../pages_admin/auth_pages/list_all_licenses";
import { ListLicensesEmailSearchResults } from "../pages_admin/auth_pages/list_licenses_email_search_results";
import { EditLicense } from "../pages_admin/auth_pages/edit_license";
import { EditAdminList } from "../pages_admin/auth_pages/edit_admin_list";
import { SendMobUpgradeInvitesPage } from "../pages_admin/auth_pages/send_mobupgrade_invites";
import { FreeMobileLicenses } from "../pages_admin/auth_pages/free_mobile_licenses";
import { ListMobUpgradeSearchResults } from "../pages_admin/auth_pages/list_mobupgrade_search_results";
import { AdminCannotChangeEmail } from "../pages_pub/portal_pages/admin_cannot_change_email";
import { ChangeEmailVerified } from  "../pages_pub/portal_pages/change_email_verified";
import { PubMainPagesProvider } from '../pages_pub/utils/pub_main_pages_provider';
import {
    ADMIN_URL,
    ADMIN_CANNOT_CHANGE_EMAIL_URL,
    ADMIN_CHANGE_EMAIL_ENDING_URL, ADMIN_EDIT_ADMIN_LIST_URL,
    ADMIN_EDIT_LICENSE_URL, ADMIN_FREE_MOBILE_LICENSES_URL,
    ADMIN_HOME_URL, ADMIN_LIST_ALL_LICENSES_URL, ADMIN_LIST_LICENSES_EMAIL_SEARCH_URL,
    ADMIN_LIST_MOBUPGRADE_SEARCH_RESULTS_URL, ADMIN_SEND_MOBUPGRADE_INVITES_URL
} from '../extlinks/portal_comm_types';


export function RoutesAdmin() : React.ReactElement {
    return (
	<>

	  <Route element={
	      <AdminPagesProvider>
		<AdminRequireAuth />
	      </AdminPagesProvider>} >
	    	    
	    <Route path={ADMIN_CHANGE_EMAIL_ENDING_URL}
		   element={
		       <PubMainPagesProvider>
			 <ChangeEmailVerified/>
		       </PubMainPagesProvider>} />
  	  </Route>

	    	    
	    <Route path={ADMIN_CANNOT_CHANGE_EMAIL_URL}
		   element={
		       <PubMainPagesProvider>
			 <AdminCannotChangeEmail/>
		       </PubMainPagesProvider>} />

	    
	  <Route element={
	      <AdminPagesProvider>
		<AdminRequireAuth />
	      </AdminPagesProvider>} >
	    
            <Route
		path={ADMIN_URL}
		element={
		    <AdminHome/>} />
	    	    
            <Route
		path={ADMIN_HOME_URL}
		element={
		    <AdminHome/>} />
	    
            <Route 
	      path={ADMIN_LIST_ALL_LICENSES_URL}
		element={
		    <ListAllLicenses/>} />	    

            <Route 
		   path={ADMIN_LIST_LICENSES_EMAIL_SEARCH_URL}
		   element={
			 <ListLicensesEmailSearchResults/>} />

            <Route 
		   path={ADMIN_EDIT_LICENSE_URL}
		   element={
			 <EditLicense/>} />

            <Route 
		   path={ADMIN_FREE_MOBILE_LICENSES_URL}
		   element={
		       <FreeMobileLicenses/>} />


            <Route 
		   path={ADMIN_EDIT_ADMIN_LIST_URL}
		   element={
			 <EditAdminList/>} />

            <Route 
	      path={ADMIN_SEND_MOBUPGRADE_INVITES_URL}
		element={
		    <SendMobUpgradeInvitesPage/>} />


            <Route 
	      path={ADMIN_LIST_MOBUPGRADE_SEARCH_RESULTS_URL}
		element={
		    <ListMobUpgradeSearchResults/>} />	    	    


	</Route>
	</>);
}
