// File: ecomm_auth_login.tsx
//

// for passwordless signin, user inputs email to request an email link
// (clicking email link will  sign the user in)

import { getAuth } from 'firebase/auth';
import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import { useParams, useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import  CircularProgress  from '@mui/material/CircularProgress';
import Backdrop  from '@mui/material/Backdrop';
import { Copyright} from '../../utils_common/copyright';
import { MsgDialogSimple} from '../../utils_common/msg_dialog';
import { TDBAppLogoLarge } from '../../utils_common/tdb_large_logo';
import { REG_LOGGER } from '../../utils_common/logger';
import TextField from '@mui/material/TextField';
import { getECommAuthServerComm } from '../utils/ecomm_auth_server_comm';
import { get_on_local_host } from '../../config/firebase_config';
import { ECOMM_AUTH_TESTING } from '../../config/config';
import { MsgStatus, ECOMM_AUTH_NOT_FOUND_URL,
    OLDPUB_CREATE_ASSIGNED_ACCT_ENDING_URL,
    ECOMM_AUTH_SIGNIN_URL
} from '../../extlinks/portal_comm_types';
import { MsgAlertDialog } from '../../utils_common/alert_dialog';

const DEBUG=true;
const TAG='ecomm_auth_login.tsx: ';

const theme = createTheme();

async function request_email_link(args : {email:string, url_params:string,
    setAlertMsg : (msg:string)=>void,
    setMsg: (msg:string)=>void}) : Promise<boolean> {

    const tag = TAG+'request_email_link: ';
	
    const server = getECommAuthServerComm();

    try {
	
	const web_host_is_local = get_on_local_host();
	
	const result = await server.handle_ecomm_auth_request_email_verify_link({
	    email:args.email,
	    web_host_is_local,
	    url_params : args.url_params,
	});
	
	if(result.error_status==null) {
	    if(result.status===MsgStatus.ERROR) {
		console.error(tag+'result.status===MsgStatus.ERROR');
		args.setMsg('Internal Error. (code=0)')
		return false;
	    }
	    return true;
	}

	switch(result.error_status) {
	    case 'SERVER_INTERNAL_ERROR':
		console.error(tag+'result.error_status'+result.error_status);		
		args.setMsg('Internal Server Error.');
		return false;
	    case 'ERROR_SENDING_EMAIL_LINK':
		console.error(tag+'result.error_status'+result.error_status);
		args.setMsg('Error requesting email link.');		
		return false;
	    case 'NO_ACCT_WITH_EMAIL_EXISTS':
		args.setMsg('No account with this email exists.');				
		return false;
	    case 'INVALID_URL_PARAMS':
		args.setMsg('Link in email is invalid.');						
		return false;
	    case 'USER_IS_ADMIN':
		//args.setMsg('Admin Users are not Allowed to Login with Email Links.');
		args.setAlertMsg('Admin user is not allowed to login by passwordless email link. Will redirect to creating account page.')		
		return false;		
	}
    } catch(error) {
	console.error(tag+'error='+error);
	args.setMsg('Internal Error. (code=1)')
	return false;
    }
}

export function ECommAuthLogin(): React.ReactElement {

    const cnt =  React.useRef<number>(0);

    React.useEffect( ()=> {    
	if(cnt.current===0) {
	    REG_LOGGER.log_event('ENTER_ECOMM_AUTH_REQUEST_EMAIL_LINK','request_login_email_link.tsx');
	}
	console.log(TAG+'cnt='+cnt.current);
	cnt.current+=1;
    },[]);

    const [ email, setEmail ] = React.useState<string>('');
    const [ msg, setMsg ] = React.useState<string>('');
    const [ alertMsg, setAlertMsg ] = React.useState<string>('');

    const params = useParams();
    const navigate = useNavigate();

    const urlParams = React.useRef<string|null>(null);

    React.useEffect(()=>{
	if(params.url_params==null) {
	    if(ECOMM_AUTH_TESTING) {
		setMsg('url_params==null');
	    } else {
		navigate(ECOMM_AUTH_NOT_FOUND_URL);
	    }
	}
	if(params.url_params != null && urlParams.current == null) {
	    urlParams.current = params.url_params;
	}
    }, [params.url_params])
    
    
    const [ linkSent, setLinkSent] = React.useState<boolean>(false);
    //const [ submitted, setSubmitted] = React.useState<boolean>(false);

    const auth = getAuth();

    const [ authChanged, setAuthChanged ] = React.useState<boolean>(false);
    const [ waiting, setWaiting] = React.useState<boolean>(false);
    
    auth.onAuthStateChanged( user=> {
	if(user && user.emailVerified) {
	    setAuthChanged(true);
	}
    });
    
    React.useEffect( () => {
	if(authChanged) {
	    console.log('authchanged')
	    navigate(ECOMM_AUTH_SIGNIN_URL);
	}
    }, [authChanged]);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
	event.preventDefault();

	if(linkSent) {
	    setMsg('Email link already sent.');
	    return;
	}
	
	if(DEBUG) console.info('handle_submit: PT1');

	if(email.trim() ==='') {
	    setMsg('Email not set.')
	    return;
	}

	if(urlParams.current==null) {
	    if(ECOMM_AUTH_TESTING) {
		setMsg('PT1: urlParams.current==null.')
	    } else {
		setMsg('Internal error.')
	    }
	    return;
	}

	setWaiting(true);
	
	request_email_link({email:email.trim(),
	    setAlertMsg,
	    url_params : urlParams.current,  setMsg}).then(
	    (result)=>{
		setWaiting(false);
		if(result) {
		    //setSubmitted(true);
		    setLinkSent(true);		
		    setMsg('Check your email for login link.');
		}
	    }).catch((error)=>{
		setWaiting(false);		
		REG_LOGGER.error(TAG+'submit_failed: error='+ error);
		setMsg('Server Error');		
	    });
	    
    }
    
    const close_dialog = () : void => {
	setMsg('');
    }
    
  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />

      <Backdrop invisible={true} open={waiting} sx={{zIndex : (thme)=>thme.zIndex.drawer + 1}} >
	 <CircularProgress/>
      </Backdrop>
      	
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >

	<Typography align='center'>
         <TDBAppLogoLarge/>
	</Typography>
      
         <Box style={{height:'0.5em'}}/>
      
          <Typography component="h1" variant="h5" sx={{mb:1}}>
            Create Account
          </Typography>
 
          <Box component="form"
	       noValidate onSubmit={(ev:React.FormEvent<HTMLFormElement>)=>handleSubmit(ev)}
	       sx={{ mt: 2 }}>
            <Grid container spacing={2}>
	      
              <Grid item xs={12} sx={{ml:0}}>	      	  	  	      
		<Typography>
Input the email for your account.  A login link will be sent to you.
		</Typography>
	      </Grid>
	      
		<Grid item xs={12}>
	    
            <TextField
		margin="normal"
		fullWidth
		id="email"
		label="Email Address"
		name="email"
		autoComplete="email"
		autoFocus
		inputProps={{ style: { textTransform: 'lowercase'} }}
		onChange={(e)=> {
		    setMsg('');
		    setEmail(e.target.value.trim().toLowerCase())}}	    
            />
              </Grid>
	      
	    </Grid>

            <Button
		type="submit"
		fullWidth
		variant="contained"
		sx={{ mt: 2, mb: 2 }}
	    >
                  SUBMIT
	    </Button>

          </Box>
	
        </Box>
	
        <MsgAlertDialog msg={alertMsg} setMsg={setAlertMsg}
			on_close={()=>{navigate(OLDPUB_CREATE_ASSIGNED_ACCT_ENDING_URL)}} />
	
	<MsgDialogSimple msg={msg}
			 button_label1={'OK'}
			 service_close={close_dialog}/>
	
        <Copyright sx={{ mt: 5 }} />
      </Container>
    </ThemeProvider>
  );
}

