// File: firebase_config.ts
//

import { initializeApp }from 'firebase/app';
import { initializeAuth, browserSessionPersistence,
         browserPopupRedirectResolver,        connectAuthEmulator,
        }from 'firebase/auth';
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
//import {   connectAuthEmulator }from 'firebase/auth';
//import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
// browserSessionPersistence
import 'firebase/firestore';

const DEBUG=false;
const TAG='firebase_config.ts: ';

const TDBC = {
//    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID
}

const X='a' + 'p' + 'i' + 'K' + 'e' + 'y';

// @ts-ignore
TDBC[X] = process.env.REACT_APP_FIREBASE_API_KEY;


export function get_on_local_host() : boolean {
    return (location.hostname === "localhost" ||
        //location.hostname === "test.localhost.com" ||
        location.hostname === "127.0.0.1");
}


function check_config(config_firebase : {[key:string]:string|undefined} ) : void {
    for(let k of Object.keys(config_firebase)) {
        if(k in  config_firebase && config_firebase[k] === undefined) {
            console.error(TAG+'check_config: k=' + k + ' === undefined');
        }
    }
}

if(DEBUG) check_config(TDBC);

const APP  = initializeApp(TDBC);

if(!APP) {
    console.error(TAG+'!APP');
}

export const AUTH = initializeAuth(APP,
 {
   persistence: browserSessionPersistence,
   popupRedirectResolver: browserPopupRedirectResolver,
});

/*
export const Providers = {
    google: new GoogleAuthProvider()
}
*/

if(DEBUG) console.info(TAG+'location.host=' + location.host);

function init_local() : null {
    if(get_on_local_host()) {
        if(process.env.REACT_APP_FIREBASE_APPCHECK_DEBUG_TOKEN===undefined) {
            if(DEBUG) {
                console.log(TAG+'process.env.FIREBASE_APPCHECK_DEBUG_TOKEN is undefined');
                console.info(TAG+'process.env=' + JSON.stringify(process.env, null, 2));
            }

        } else {
            //console.log(TAG+'process.env.REACT_APP_FIREBASE_APPCHECK_DEBUG_TOKEN='+process.env.REACT_APP_FIREBASE_APPCHECK_DEBUG_TOKEN);
            //  @ts-ignore
            self.FIREBASE_APPCHECK_DEBUG_TOKEN=process.env.REACT_APP_FIREBASE_APPCHECK_DEBUG_TOKEN;      
        }
    }
    return null;
}

export const INIT_LOCAL=init_local();


function initialize_app_check() : void {
    //console.log(TAG+'entering initialize_app_check');
    if(using_emulator()) {
        init_local();
    }
    initializeAppCheck(APP, {
        provider: new ReCaptchaV3Provider('6LcEfS8mAAAAAGlFLtdO6n6hX2S1N_EPo7amB0Sm'),
        // Optional argument. If true, the SDK automatically refreshes App Check
        // tokens as needed.
        isTokenAutoRefreshEnabled: true
    })    
}


// Pass your reCAPTCHA v3 site key (public key) to activate(). Make sure this
// key is the counterpart to the secret key you set in the Firebase console.
// (need to define variable APPCHECK or else function will get called each
// time this file is imported)
export const APPCHECK = initialize_app_check();


function using_emulator() : boolean {
    return process.env.FUNCTIONS_EMULATOR !== undefined ||
        process.env.REACT_APP_FUNCTIONS_EMULATOR !== undefined;
}


function init_emulator() : null {
    if(using_emulator()) {
        if(DEBUG) console.log('process.env.FUNCTIONS_EMULATOR=', process.env.FUNCTIONS_EMULATOR);    
        const functions = getFunctions(APP);
        
        connectFunctionsEmulator(functions, "localhost", 5001);
        //connectFunctionsEmulator(functions, "test.localhost.com", 5001);
        
        console.log(TAG+'******connect to emulator************');

        /*
        const db = getFirestore(APP);
        connectFirestoreEmulator(db, 'localhost', 8080);
        */

        connectAuthEmulator(AUTH, "http://localhost:9099");

    }
    return null;
}

export const INIT_EMULATOR=init_emulator();


/*
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function get_firebase_functions_portalcomm_raw() : string {
    if(process.env.REACT_APP_PROJECT_ID === undefined) {
        console.error(TAG+'process.env.REACT_APP_PROJECT_ID === undefined');
        return 'https://portalcomm-nigx7kifkq-uc.a.run.app';        
    } else if(process.env.REACT_APP_PROJECT_ID==='auth-mobile-dev') {
        return 'https://portalcomm-nigx7kifkq-uc.a.run.app';
    } else if(process.env.REACT_APP_PROJECT_ID==='auth-mobile-prod') {
        return 'https://portalcomm-3flntj72rq-uc.a.run.app';
    } else {
        console.error(TAG+'unknown: process.env.REACT_APP_PROJECT_ID='+
            process.env.REACT_APP_PROJECT_ID);
        return 'https://portalcomm-nigx7kifkq-uc.a.run.app';                
    }
}*/

function get_firebase_functions_baseurl_rewrites() : string {
    
    if(using_emulator()) {
        return 'http://localhost:5001/auth-mobile-dev/us-central1';
        //return 'http://test.localhost.com:5001/auth-mobile-dev/us-central1';
    }
    
    if(process.env.REACT_APP_PROJECT_ID === undefined) {
        console.error(TAG+'process.env.REACT_APP_PROJECT_ID === undefined');
        return 'https://dev-auth1.discoverybible.com/api';        
    } else if(process.env.REACT_APP_PROJECT_ID==='auth-mobile-dev') {
        return 'https://dev-auth1.discoverybible.com/api';                
    } else if(process.env.REACT_APP_PROJECT_ID==='auth-mobile-prod') {
        return 'https://auth1.discoverybible.com/api';                
    } else {
        console.error(TAG+'unknown: process.env.REACT_APP_PROJECT_ID='+
            process.env.REACT_APP_PROJECT_ID);
        return 'https://dev-auth1.discoverybible.com/api';                        
    }
}

export function get_firebase_functions_portalcomm() : string {
    return get_firebase_functions_baseurl_rewrites() + '/portalcomm';    
}

export function get_firebase_functions_api() : string {
    return get_firebase_functions_baseurl_rewrites() + '/api';
}


export function get_firebase_functions_adminportalcomm() : string {
    return get_firebase_functions_baseurl_rewrites() + '/adminportalcomm';
}

export function get_portal_domain_url():string | null {

    if(get_on_local_host()) {
        return 'http://localhost:3000';
        //return 'http://test.localhost.com:3000';
    }

    
    if(process.env.REACT_APP_PORTAL_DOMAIN) {
        return 'https://' + process.env.REACT_APP_PORTAL_DOMAIN;
    }

    console.error('process.env.REACT_APP_PORTAL_DOMAIN is undefined');
    return null;
}

//const DEV = process.env.NODE_ENV==='development';

//if(DEV) console.info('firebase.ts: process.env=' + JSON.stringify(process.env, null, 2));
//else    console.info('firebase.ts: process.env.NODE_ENV=' + process.env.NODE_ENV);

// to turn on auth emulator
//auth.useEmulator("http://localhost:9099");

