// File: mobile_upgrade_landing.tsx
//

import React from 'react';
import { useParams, useNavigate} from 'react-router-dom';
import Box from '@mui/material/Box';
//import Button from '@mui/material/Button';
//import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { Copyright } from '../../utils_common/copyright';
import { TDBAppLogoLarge } from '../../utils_common/tdb_large_logo';
//import { getServerComm } from '../utils/server_comm';
//import { ReqEmailMobileActCode, MsgStatus } from '../../extlinks/portal_comm_types';
//import { get_session_token } from '../../utils_common/logger';
import { MsgAlertDialog } from '../../utils_common/alert_dialog';
//import { Progress } from '../../utils_common/progress';
import { EmailCommLandingPage } from '../components/email_comm_landing_page';
//import { RequestEmailAndPin } from '../utils_common/request_email_and_pin';
//import { MsgStatus } from '../extlinks/portal_comm_types';
//import { getECommServerComm } from './utils/ecomm_server_comm';
import { check_url_params } from '../utils/check_url_params';
//import { LaunchMobileUpgradePopup } from './components/launch_mobile_upgrade_popup';

//const DEBUG=true;
const TAG="mobile_upgrade_landing.tsx: ";

//const TESTING=true;

//  !!!!!!!!!! NOT DONE  !!!!!!!!!!
export function ECommAuthMobileUpgradeLanding() : React.ReactElement {

    console.log(TAG);
    
    const [ msg, setMsg ] = React.useState<string>('');    

    const { url_params } = useParams();
    
    const navigate = useNavigate();
    
    console.log('url_params='+url_params);

    React.useEffect( ()=> {
	check_url_params({parent_tag:TAG, url_params,
	    navigate});
    },[url_params]);
    
    return (
      
	<EmailCommLandingPage challengeOff={true}>
	    
  	    <Typography align='center'>
              <TDBAppLogoLarge/>
 	    </Typography>
        
	  <>  { url_params !== undefined &&
	         (<Box  sx={{mt:4}}>
{/*		   <LaunchMobileUpgradePopup url_token={token}/> */}
		 </Box>)
	    }    </>
	    
	  <Copyright sx={{ mt: 5, mb: 4 }} />
	  
	  <MsgAlertDialog msg={msg} setMsg={setMsg} />
	  
	{/*  <Progress isLoading={isLoading}/> */}
	  
	</EmailCommLandingPage>
	
    );

}
