// File: password_reset_from_link.tsx
//

import * as React from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';
import { Link, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import  CircularProgress  from '@mui/material/CircularProgress';
import Backdrop  from '@mui/material/Backdrop';
import { Copyright} from '../../utils_common/copyright';
import {IPageProps} from '../interfaces/page';
import { MsgDialogSimple} from '../../utils_common/msg_dialog';
import { TDBAppLogoLarge } from '../../utils_common/tdb_large_logo';
import { PasswordField} from '../../utils_common/password_field';
import { check_password_strength } from '../../utils_common/password_strength';
import { REG_LOGGER } from '../../utils_common/logger';
import TextField from '@mui/material/TextField';
import { MsgStatus, OLDPUB_SIGNIN_ENDING_URL } from '../../extlinks/portal_comm_types';
import { getServerComm } from '../utils/server_comm';
import { MOBUPGRADE_URL_TAG } from './email_verified';
import {  useParams } from 'react-router-dom';

const TAG='password_reset_from_link.tsx: ';
const DEBUG=true;

const theme = createTheme();

function
PasswordResetFromLinkImpl(props : {
    url_token:string,
    msg:string,
    setMsg:(s:string)=>void,
    setWaiting: (b:boolean)=>void
}): React.ReactElement {

    const setMsg = props.setMsg;
    const setWaiting = props.setWaiting;
    
    const [ email, setEmail ] = React.useState<string>('');
    const [ password, setPassword ] = React.useState<string>('');
    const [ submitted, setSubmitted ] = React.useState<boolean>(false);
    
    const server = getServerComm();

    console.log(TAG+'url_token='+props.url_token);

    const submit_failed = (error:string) : void => {
	REG_LOGGER.error(TAG+'submit_failed: error='+ error);
	setMsg(error);
    }

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
	event.preventDefault();    
	
	if(DEBUG) console.info('entering signin_and_send_link');

	setMsg('');
	setWaiting(true);

	const check_password_msg = check_password_strength(password); 
	if( check_password_msg.trim() !== '') {
	    setMsg(check_password_msg);
	    setWaiting(false);	    
	    return;
	}
	

	server.handle_reset_password_from_url_token({
	    url_token: props.url_token,
	    email,
	    password:password,
	    
	}).then((result)=> {
	    
	    setWaiting(false);
	    
	    if(result.error_msg != null) {

		setMsg(result.error_msg);
		
	    } else if(result.status===MsgStatus.ERROR) {

		setMsg('Server error.');		

		
	    } else {
		setSubmitted(true);		
		setPassword('');
		setMsg('Password  successfully changed.');
	    }
	}).catch( (error)=>{
	    setWaiting(false);
	    submit_failed(error);	    
	});
    }
    
    const close_dialog = () : void => {
	setMsg('');
    }
    
  return (<>
 
          <Box component="form"
	       noValidate onSubmit={(ev:React.FormEvent<HTMLFormElement>)=>handleSubmit(ev)}
	       sx={{ mt: 2 }}>

		<Typography variant='h6'>
            Input your email
		</Typography>

            <TextField
		margin="normal"
		fullWidth
		id="email"
		label="Email Address"
		name="email"
		autoComplete="email"
		autoFocus
      		inputProps={{ style: { textTransform: 'lowercase'} }}
		onChange={(e)=> {
		    setMsg('');
		    setEmail(e.target.value.trim().toLowerCase())}}	    
            />
	    
		<Typography sx={{mb:1,mt:1}} variant='h6'>
               Input a new account password
		</Typography>
		
	      <PasswordField  id='newpw'
			 fullWidth={true}
			 adornment="New Password"
           		 label="New Password1"
			 password={password}
			 setPassword={setPassword}
			 onChange={()=> {
			     if(props.msg !== '') setMsg('');
			 }}/>

            <Button
		type="submit"
		fullWidth
		variant="contained"
		sx={{ mt: 2, mb: 2 }}
	    >
                  SUBMIT
	    </Button>
	    
	  {  submitted &&
	      (<Box>
                <Link to={OLDPUB_SIGNIN_ENDING_URL}>
                  <Typography textAlign='center'
		      variant='h6'>Sign in</Typography>
                </Link>
	      </Box>)	     
         }

        </Box>
 </>
  );
}


export function PasswordResetFromLink(_props: IPageProps): React.ReactElement {

    const [ msg, setMsg ] = React.useState<string>('');

    const [ waiting, setWaiting ] = React.useState<boolean>(false);
    
    const server = getServerComm();
    
    const [urlToken,setUrlToken] =  React.useState<string|null>(null);

    const token_expired = React.useRef<boolean|null>(null);

    const params = useParams();

    const navigate = useNavigate();    
    
    React.useEffect(()=> {

	if(params.token === undefined) {
	    console.error(TAG+'url_token==undefined');
	    navigate(OLDPUB_SIGNIN_ENDING_URL);
	}

	setUrlToken(params.token===undefined? null: params.token);
	
	if(token_expired.current==null && urlToken !=null) {
	    console.log(TAG+'before handle_reset_password_from_url_token_not_expired');
	    server.handle_reset_password_from_url_token_not_expired({
		url_token : urlToken
	    }).then((result)=>{
		if(result.status !== MsgStatus.ERROR) {
		    token_expired.current = ! result.not_expired
		    console.log(TAG+'token_expired.current='+token_expired.current);
		    if(token_expired.current) {
			setMsg('Password reset link expired.');
		    }
		} else {
		    console.error(TAG+'handle_reset_password_from_url_token_not_expired: returned ERROR');
		}
	    }).catch((error)=>{
		console.error(TAG+'handle_reset_password_from_url_token_not_expired: error'+error);
	    });
	}
    })
    
    const close_dialog = () : void => {
	setMsg('');
    }
    
    return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />

      <Box style={{height:'0.5em'}}/>
	  
       <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >

  	  <Typography align='center'>
           <TDBAppLogoLarge/>
	  </Typography>

      <Box sx={{mt:4}}>
          <Typography component="h1" variant="h5" textAlign='center' sx={{mb:1}}>
            Reset Password
          </Typography>
      </Box>

	  { ! token_expired.current && urlToken != null &&
	      
 	    (<PasswordResetFromLinkImpl msg={msg} setMsg={setMsg} setWaiting={setWaiting}
					url_token={urlToken}
	     />)
	  }
	    
	</Box>

	<MsgDialogSimple msg={msg}
			 button_label1={'OK'}
			 service_close={close_dialog}/>
	
        <Copyright sx={{ mt: 5 }} />

        <Backdrop invisible={true} open={waiting} sx={{zIndex : (thme)=>thme.zIndex.drawer + 1}} >
  	 <CircularProgress/>
        </Backdrop>
	
      </Container>
    </ThemeProvider>
    );
}
