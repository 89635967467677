// File: home.tsx
//

import * as React from 'react';
import { Link } from 'react-router-dom';
import { MainLayout} from '../utils/main_layout';
import { useMainPagesContext } from '../utils/pub_main_pages_provider';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Backdrop  from '@mui/material/Backdrop';
import  CircularProgress  from '@mui/material/CircularProgress';
import { AdminRole, OLDPUB_ACTIVATE_MOBILE_URL, OLDPUB_DESKTOP_APP_KEY_URL, OLDPUB_DISPLAY_MOBILE_URL, OLDPUB_MOBUPGRADE_URL } from '../../extlinks/portal_comm_types';
import { PubMainPagesContextState } from '../utils/pub_main_pages_provider';
import { ADMIN_HOME_URL,GetUserLicensesInfo} from '../../extlinks/portal_comm_types';

const TAG='home.tsx: ';
const DEBUG=true;
 
const theme = createTheme();

type LicenseInfo = GetUserLicensesInfo.LicenseInfo;

function
get_upgradeable_license_infos(main_state_context :  PubMainPagesContextState) : LicenseInfo[] {

    const license_infos = main_state_context.user_licenses_info == null ?  []
    : main_state_context.user_licenses_info.license_infos;
    
    return license_infos.filter( (x)=> {
	if(x.expiry_date != null && 
	    (new Date(x.expiry_date)).getTime() < Date.now()) return false;
	return  !x.license_type.has_upgraded_to_mobile &&
	    x.license_type.includes_upgrade_to_mobile;
    });
}

function Items(props : {main_state_context :  PubMainPagesContextState}) :  React.ReactElement {

    return (
	<>
	  
	  {props.main_state_context.user_admin_role !== AdminRole.NONE &&
  	  (<Box sx={{mb:5}}>
	   <Link to={ADMIN_HOME_URL} style={{fontWeight:'bold'}}> Admin Console*</Link>
	  </Box>)
	  }

	  { get_upgradeable_license_infos(props.main_state_context).length > 0 &&
	  (<Box sx={{mb:3}}  style={{fontWeight:'bold'}}>
	   <Link to={OLDPUB_MOBUPGRADE_URL}> Mobile Upgrade Offer</Link>
	  </Box>)
	  }
	  
	  <Box sx={{mb:3}}  style={{fontWeight:'bold'}}>
	   <Link to={OLDPUB_ACTIVATE_MOBILE_URL}> Activate Mobile Device</Link>
	  </Box>

	  
	  <Box sx={{mb:3}}  style={{fontWeight:'bold'}}>
	   <Link to={OLDPUB_DISPLAY_MOBILE_URL}> View Your Mobile Devices</Link>
	  </Box> 	    	  	  
	  
	  <Box sx={{mb:3}}  style={{fontWeight:'bold'}}>
	   <Link to={OLDPUB_DESKTOP_APP_KEY_URL}> View Your Desktop Licenses</Link>
	  </Box> 	    	  	  
	  	  
	</>
    )
}

export function Home() : React.ReactElement {

    const mainStateContext = useMainPagesContext();
    const mainPagesIsLoading = mainStateContext.is_loading;

    /*
    React.useEffect( ()=>{
	const auth = getAuth();
	const user = auth.currentUser;
	if(user) {
	    user.getIdToken(true).then( ()=>{
		user.getIdTokenResult(true).then(
		    (idTokenResult)=> {
			console.log(TAG+'customClaims'+JSON.stringify(idTokenResult.claims,null,2));
		}).catch((error)=>{
		    console.error('error='+error);
		})
	    }).catch((error)=> {
		    console.error('error='+error);		
	    });
	}	
    },[])*/
    
    return (
	<ThemeProvider theme={theme}>	
	    <CssBaseline />	      
	    <MainLayout title='Home'
			dont_refresh_user_info={true}
	    >
	      <Box sx={{mt:2, mb:4, display:'flex', flexDirection:'column', alignItems:'center'}}>
              <Items main_state_context={mainStateContext}/>
	      </Box>	      

	  <Backdrop invisible={true} open={mainPagesIsLoading}
		    sx={{zIndex : (thme)=>thme.zIndex.drawer + 1}} >
	    <CircularProgress/>
	  </Backdrop>

	    </MainLayout>

	    
	</ThemeProvider>
  );
}
