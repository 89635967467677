// File: desktop_install.tsx
//

import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { getECommAuthServerComm } from '../utils/ecomm_auth_server_comm';
import { useLocation } from 'react-router-dom';
import { MsgStatus, ECommAuth, ECOMM_AUTH_HOME_URL } from '../../extlinks/portal_comm_types';
import { check_url_params } from '../utils/check_url_params';
import { Copyright } from '../../utils_common/copyright';
import { MsgAlertDialog } from '../../utils_common/alert_dialog';
import { Progress } from '../../utils_common/progress';
import CssBaseline from '@mui/material/CssBaseline';

const TAG='desktop_install.tsx: ';
const DEBUG=true;

type Licenses = ECommAuth.ReqLicenseKeys.Licenses;
//type DesktopLicense = ECommAuth.ReqLicenseKeys.DesktopLicense;

const TDB_TRIAL_URL='https://discoverybible.com/free-trial/'

//const FONT='body1';
const FONT_COLOR='#008B8B';

function ContentCard(props : {title:string, children : string|React.ReactElement|
                           React.ReactElement[]} ) :  React.ReactElement {
			       
    //const { url_params } = useParams();    
    //const navigate = useNavigate();
    			       
    return (
	<Box style={{height:'100%', width:'80%', backgroundColor:'white', borderRadius:'6pt'}}
	     sx={{mt:0,mb:5, boxShadow:3, p:3}}
	     >

	  <Typography align='center'
		      variant='h5'
		      sx={{mb:2}}
		      style={{color:FONT_COLOR}}>
            {props.title}	    
	  </Typography>

	  
	  <Box style={{width:'100%', height:'100%'}}>
		{props.children}
          </Box>	    
	</Box>
	);
}

function ScrollToTop() :  React.ReactElement {

    const { pathname } = useLocation();

    React.useEffect( ()=>{
	window.scrollTo(0, 0);
    },[pathname]);

    return (<></>);
}


function  DesktopAppInstallInstructionsImpl() : React.ReactElement {

    return (
	
      <Box  style={{width:'100%'}}>
      <ScrollToTop/>
    
      <Box style={{width:'100%',display:'flex', flexDirection:'column',
	  alignItems : 'center', justifyContent:'center'}}>

	<Box sx={{mb:4}}>
	  <Typography textAlign='center' style={{fontWeight:'bold', fontSize:"1.8em"}}>
	    Desktop App Installation Instructions
	  </Typography>	    
	</Box>	  
	  
          <ContentCard title="First install the Trial Version of the desktop app from the Discovery Bible websitei, if you haven't done so already.">
	    <Box style={{display:'flex', flexDirection:'column',
		alignItems : 'center', justifyContent:'flex-start'}}>	    
	      
	      <Box style={{display:'flex', flexDirection:'column',
		  marginTop:'0.0em',
		  alignItems : 'center'}}>

		<Box style={{display:'flex', flexDirection:'row'}}>
		<Link 	variant='h5' 
	                 style={{ textDecoration:'none'}}
	                 href={TDB_TRIAL_URL} >
                Go here.
		</Link>
		</Box>
				
	      </Box>
	      
	    </Box>
	  </ContentCard>
	  
          <ContentCard  title="Press the 'Help' button.">
	    <Box style={{padding:'0',margin:'0',display:'flex', flexDirection:'column',
		alignItems : 'center', justifyContent:'flex-start'}}>
	      
              <img style={{margin:'0',objectFit:'cover', width:'90%' }}
	           src={require('./desktop-images/desktop-app1-mod.png')} alt=""/>


            </Box>
	  </ContentCard>
 	  
          <ContentCard title="Press the 'Enter license key'.">
	    <Box 	
		 style={{display:'flex', flexDirection:'column',
		alignItems : 'center', justifyContent:'center'}}>
              <img   src={require('./desktop-images/desktop-app2-mod.png')} alt=""
	        style={{ objectFit:'contain', width:'90%'}}/>	      

	    </Box>

	  </ContentCard>

          <ContentCard title="Input email and activation code">

	    <Box
		sx={{mb:2,mt:2}}
		 style={{display:'flex', flexDirection:'column',
		alignItems : 'center', justifyContent:'flex-start'}}>

	    <Box
		sx={{mt:1}}
		 style={{display:'flex', flexDirection:'column',
		alignItems : 'center', justifyContent:'flex-start'}}>
              <img   src={require('./desktop-images/desktop-app3-mod.png')} alt=""
	        style={{margin:'0em',  objectFit:'contain',  width:'90%'}}/>
	      
	    </Box>

	    
	    <Box sx={{mt:1}}>
	      <Typography variant='h6'>	      
	      Next, press the Submit button.
	      </Typography>
           </Box>
	    <Box sx={{mt:1}}>	      
	      <Typography  variant='h6'>	      
	      You are now all set to use the full features of the Discovery Bible.
	      </Typography>		      
	    </Box>
	    
	    </Box>
	  </ContentCard>


          <ContentCard title="Input email and activation code">

	    <Box
		sx={{mb:2,mt:2}}
		 style={{display:'flex', flexDirection:'column',
		alignItems : 'center', justifyContent:'flex-start'}}>

	    <Box
		sx={{mt:1}}
		 style={{display:'flex', flexDirection:'column',
		alignItems : 'center', justifyContent:'flex-start'}}>
              <img   src={require('./desktop-images/desktop-app4.png')} alt=""
	        style={{margin:'0em',  objectFit:'contain', width:'90%'}}/>
	      
	    </Box>

	    
	    <Box sx={{mt:1}}>
	      <Typography variant='h6'>	      
	      Next, press the Submit button.
	      </Typography>
           </Box>
	    <Box sx={{mt:1}}>	      
	      <Typography  variant='h6'>	      
	      You are now all set to use the full features of the Discovery Bible.
	      </Typography>		      
	    </Box>
	    
	    </Box>
	  </ContentCard>	  	  	  	  
	
          <ContentCard title="Input email and activation code">

	    <Box
		sx={{mb:2,mt:2}}
		 style={{display:'flex', flexDirection:'column',
		alignItems : 'center', justifyContent:'flex-start'}}>


	    <Box
		sx={{mt:1}}
		 style={{display:'flex', flexDirection:'column',
		alignItems : 'center', justifyContent:'flex-start'}}>
              <img   src={require('./desktop-images/desktop-app5.png')} alt=""
	        style={{margin:'0em' , objectFit:'contain', width:'90%'}}/>
	      
	    </Box>
	    
	    <Box sx={{mt:1}}>
	      <Typography variant='h6'>	      
	      Next, press the Submit button.
	      </Typography>
           </Box>
	    <Box sx={{mt:1}}>	      
	      <Typography  variant='h6'>	      
	      You are now all set to use the full features of the Discovery Bible.
	      </Typography>		      
	    </Box>
	    
	    </Box>
	  </ContentCard>	  	  	  	  
		
          <ContentCard title="Input email and activation code">

	    <Box
		sx={{mb:2,mt:2}}
		 style={{display:'flex', flexDirection:'column',
		alignItems : 'center', justifyContent:'flex-start'}}>


	    <Box
		sx={{mt:1}}
		 style={{display:'flex', flexDirection:'column',
		alignItems : 'center', justifyContent:'flex-start'}}>
              <img   src={require('./desktop-images/desktop-app6-mod.png')} alt=""
	        style={{margin:'0em',  objectFit:'contain', width:'90%'}}/>
	      
	    </Box>

	    
	    <Box sx={{mt:1}}>
	      <Typography variant='h6'>	      
	      Next, press the Submit button.
	      </Typography>
           </Box>
	    <Box sx={{mt:1}}>	      
	      <Typography  variant='h6'>	      
	      You are now all set to use the full features of the Discovery Bible.
	      </Typography>		      
	    </Box>
	    
	    </Box>
	  </ContentCard>	  	  	  	  
	
      </Box>

  	   </Box>

    )
}


export function ECommAuthDesktopInstallInstructions(props:{}) : React.ReactElement {

    const count = React.useRef<number>(0);
    
    const navigate = useNavigate();

    const server = getECommAuthServerComm();
    
    React.useEffect( ()=> {
	count.current = count.current+1;
	if(DEBUG) console.log('***count='+count.current);
	if(count.current > 50) navigate(ECOMM_AUTH_HOME_URL);	
    },[])

    const { url_params } = useParams();
			       
    const [ msg, setMsg ] = React.useState<string>('');
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const licenses = React.useRef<Licenses|null>(null);

    React.useEffect( ()=> {
	check_url_params({parent_tag:TAG, url_params,  navigate});
    },[url_params]);

    
    React.useEffect( ()=> {

	if(url_params && licenses.current == null) {
	    setIsLoading(true);
	    //setNoLicenses(false);
	    server.handle_req_license_keys({url_params}).then((result)=>{
		setIsLoading(false);
		if(result.status === MsgStatus.ERROR) {
		    //setNoLicenses(true);		    
		    setMsg('Server error. Try again later.');
		} else {
		    licenses.current = result.licenses;
		    if(result.licenses == null) {
			//setNoLicenses(true);
		    }
		}
	    }).catch((error)=>{
		setIsLoading(false);	    
	    });
	}

    },[]);
    
    return(

        <Container component="main" maxWidth={false}
		   sx={{mt:0, pt:5, pb:2}}
		   style={{width:'100vw', backgroundColor : 'rgb(240, 240, 240)'}}>
      <CssBaseline/>
          <DesktopAppInstallInstructionsImpl/>

	  <Copyright sx={{ mt: 5, mb: 4 }} />
	  
	  <MsgAlertDialog msg={msg} setMsg={setMsg} />
	  
	{ <Progress isLoading={isLoading}/> }

      </Container>

    )
}

    
